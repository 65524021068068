export const ES = {
  AactivityOnboarding: {
    SUMMARY: {
      ACTIVITY_TEXT: 'Frecuencia de las salidas'
    }
  },
  HipayPayments: {
    CARDNUMBER: 'Número de tarjeta',
    EXPIRYDATE: 'Fecha de caducidad',
    FULLNAME: 'Nombre completo',
    GOCARDLESS_LINK: 'Pagar con domiciliación bancaria'
  },
  Slider: {
    GPS_INTERVAL: 'Intervalo GPS',
    GPS_POSITION_REPORT_ENABLED: 'GPS position report enabled',
    TRACKER_INTERVAL_TEXT_WARNING: 'Al desactivar la señal automática de la posición GPS, aumentarás la duración de la batería. Puedes solicitar una nueva posición manualmente, pero las zonas seguras y el historial dejarán de estar disponibles.'
  },
  TypeSwitch: {
    ANIMAL_SUBTITLE: 'Elige el animal para el que has comprado el localizador:',
    CAT_TYPE: 'Localizador gato',
    DOG_TYPE: 'Trazador por perro',
    FAMILY_SUBTITLE: '¿Para quién has comprado este localizador?',
    KID_TYPE: 'Un niño',
    SILVER_TYPE: 'Una persona mayor',
    TITLE: '¿Para quién es el localizador?'
  },
  account: {
    LANGUAGE: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema',
        UPDATE_SUCCESS: 'Idioma actualizado correctamente'
      }
    },
    NAV: {
      LANGUAGE: 'Idioma',
      PASSWORD: 'Contraseña',
      PAYMENTS: 'Pagos y facturación',
      USER_PREFERENCES: 'Preferencias',
      USER_PROFILE: 'Perfil usuario'
    },
    PASSWORD: {
      HEADLINE: 'Cambio de contraseña',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema',
        UPDATE_ERROR_WRONG_PASSWORD: 'La contraseña anterior introducida no es correcta',
        UPDATE_SUCCESS: 'Contraseña actualizada correctamente'
      }
    },
    PAYMENTS: {
      HISTORY_HEADLINE: 'Historial de pagos',
      MAIN_HEADLINE: 'Facturación'
    },
    USER_PREFERENCES: {
      EMAIL_PREFERENCES_HEADLINE: 'Preferencias de correo electrónico',
      EMAIL_PREFERENCES_USER_INFO: 'Las preferencias se aplicarán a la dirección del correo electrónico {{user_email}}',
      MAIN_HEADLINE: 'Preferencias',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema al guardar las preferencias',
        UPDATE_SUCCESS: 'Preferencias actualizadas correctamente'
      },
      METRIC_PREFERENCES_DESCRIPTION: 'Elija la unidad de distancia utilizada para su localizador.',
      METRIC_PREFERENCES_HEADLINE: 'Preferencia del sistema métrico :'
    },
    USER_PROFILE: {
      BASIC_INFO_HEADLINE: 'Información básica',
      BILLING_HEADLINE: 'Dirección de facturación',
      MAIN_HEADLINE: 'Perfil usuario',
      MARKETING_AGREEMENT_TEXT: 'Acepto recibir ofertas y promociones de los socios de Weenect',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema',
        UPDATE_SUCCESS: 'Datos actualizados correctamente'
      },
      METRIC_KM: 'Kilómetros',
      METRIC_MILES: 'Millas',
      PREFERRED_METRIC: 'Sistema métrico:'
    }
  },
  activity: {
    AVERAGE_SPEED: 'Velocidad media',
    CONTROLS: {
      PAUSE_PLAYING_TITLE: 'Suspender la grabación del historial',
      RESUME_PLAYING_TITLE: 'Reiniciar la grabación del historial',
      START_PLAYING: 'Lanzar',
      START_PLAYING_TITLE: 'Iniciar la grabación del historial',
      STOP_PLAYING: 'Detener',
      STOP_PLAYING_TITLE: 'Parar la grabación del historial',
      TOGGLE_HEATMAP: 'Mapa térmico',
      TOGGLE_HEATMAP_TITLE: 'Mostrar el mapa térmico',
      TOGGLE_PATH: 'Trazado de posiciones',
      TOGGLE_PATH_TITLE: 'Mostrar el trazado de posiciones'
    },
    DETAIL_TITLE: 'Información de la actividad',
    EMPTY_LIST: 'No hay alertas que mostrar. Modifique el período seleccionado o compruebe que el localizador esté encendido.',
    EVENTS: {
      ALERT: 'Botón lateral activado',
      BATTERY: 'Batería baja - {{battery}}% restant',
      CALL_END: 'Llamada SOS finalizada',
      CALL_REQ: 'Llamada SOS solicitada',
      ENTERS_GEOFENCE: 'Entrada en la zona',
      EXIT_GEOFENCE: 'Salida de la zona',
      FLASH_REQ: 'Flash pedido',
      FREQ_CHANGE: 'Se ha modificado el intervalo de tiempo',
      RING: 'Timbre emitido',
      RING_REQ: 'Timbre solicitado',
      SOS: 'Alerta SOS',
      TRACKER_OFF: 'Localizador apagado',
      TRACKER_ON: 'Localizador encendido',
      VIBRATE: 'Vibración emitida',
      VIBRATE_REQ: 'Vibrador solicitado'
    },
    FEEDBACKTEXT: {
      FIRST: '',
      SECOND: ''
    },
    HISTORY_TITLE: 'Histórico de las notificaciones',
    LAST_TIME_PERIOD: 'Mostrar las últimas',
    LOADING: 'Cargando...',
    MAXIMUM_SPEED: 'Velocidad máxima',
    OBJECTIVE: {
      CONTENT: 'Establece un objetivo de actividad intensa para alcanzar cada día.',
      DAILY_REACHED: '{{name}} ha alcanzado su objetivo diario.',
      HELPTEXT: 'Sólo se tiene en cuenta la actividad intensa a la hora de medir el objetivo diario. Por ejemplo, una carrera o una sesión de juego.',
      RECOMENDED_VALUE: 'Objetivo recomendado: 1 hora',
      SUBTEXT: 'Nuestro algoritmo registra cada movimiento de tu localizador y luego clasifica los resultados por nivel de intensidad. Así podemos determinar si se trata de actividad intensa, vigilia o descanso.',
      TITLE: 'Objetivo diario'
    },
    ONBOARDING: {
      FIRST_SUBTEXT: 'Esta herramienta te permite hacer un seguimiento diario de tu compañero peludo.',
      SECOND_SUBTEXT: '¿Cuánto tiempo durmió? ¿Cuántos pasos ha dado hoy?',
      START: 'Empezar',
      THIRD_SUBTEXT: 'Incluso podrá establecer un objetivo basado en la raza de su mascota, para que ésta realice la actividad diaria que necesita.',
      TITLE: '¡Bienvenido al seguimiento de actividad Weenect!'
    },
    PROGRESSBAR: {
      TITLE: 'Objetivo diario'
    },
    SELECT_DAY: 'Elegir el día que mostrar',
    SELECT_TIME: 'Use el control deslizante para seleccionar el intervalo de tiempo a mostrar',
    SUMMARY: {
      ACTIVITY: 'Intenso',
      AWAKENING: 'Moderado',
      DATEINTERVAL: '',
      DAY_INDEX: 'd',
      DISTRIBUTIONTEXT: 'Informe detallado',
      HOUR_INDEX: 'h',
      LASTWEEK: '',
      MENU_TITLE: 'Informe de actividad',
      MIN_INDEX: 'min',
      MODIFY_PERIOD: 'Cambiar el período',
      PREVIOUS_DAY: '',
      PREVIOUS_MONTH: '',
      PREVIOUS_WEEK: '',
      REGISTERED_ACTIVITY: 'passos',
      REPORT: 'Ver informe ',
      REST: 'Bajo',
      SUBTEXT: '{{time}} más de actividad intensa antes de alcanzar el objetivo de {{name}}.',
      TEXT_OBJECTIVE_COMPLETED: '{{name}} ha alcanzado su objetivo diario.',
      TEXT_OBJECTIVE_COMPLETED_TREAT: 'Enhorabuena, {{name}} ha conseguido su objetivo. ¿Quizás eso merezca un trato?',
      TEXT_OBJECTIVE_NONE: '{{name}} no tiene datos para este periodo',
      TEXT_OBJECTIVE_NONE_VERIFY_TRACKER: '{{name}} aún no ha localizador ningún movimiento. Comprueba que tu localizador está encendido y que la batería está cargada.',
      TITTLE: 'El progreso de {{name}}',
      TODAY: 'Hoy,',
      TOTAL: 'Total',
      YESTERDAY: 'ayer'
    },
    TIME_ELAPSED: 'Duración',
    TOTAL_DISTANCE: 'Distancia total'
  },
  activityGeneral: {
    BEHAVIOR: 'Actividad',
    STANDARD_MORPHOLOGY: 'Morfología',
    STERILIZED: 'Esterilizado'
  },
  activityOnboarding: {
    COMMON: {
      STEP: 'Etapa',
      TRACKER_PROFIL: 'Perfil del localizador'
    },
    MONTH: {
      APRIL: 'Abril',
      AUGUST: 'Agosto',
      DECEMBER: 'Diciembre',
      FEBRUARY: 'Febrero',
      JANUARY: 'Enero',
      JULY: 'Julio',
      JUNE: 'Junio',
      MARCH: 'Marzo',
      MAY: 'Mayo',
      NOVEMBER: 'Noviembre',
      OCTOBER: 'Octubre',
      SEPTEMBER: 'Septiembre'
    },
    PET_ACTIVITY: {
      FIRST_BUTTON: 'Casero (lo estrictamente necesario)',
      FIRST_BUTTON_CAT: 'Excepcionalmente (vacaciones, imprevistos...)',
      FIRST_BUTTON_DOG: 'Sólo para sus propias necesidades',
      FOURTH_BUTTON: 'Hiperactivo (de 2 a 3 paseos al día)',
      FOURTH_BUTTON_CAT: 'Regularmente, día y noche',
      FOURTH_BUTTON_DOG: '2 a 3 paseos/día',
      SECOND_BUTTON: 'Normal (de 2 a 3 paseos a la semana)',
      SECOND_BUTTON_CAT: 'De vez en cuando, a petición suya\n',
      SECOND_BUTTON_DOG: '2 a 3 paseos/semana',
      SELECT_ACTIVITY: '¿Con qué frecuencia sale?',
      THIRD_BUTTON: 'Enérgico (un paseo al día)',
      THIRD_BUTTON_CAT: 'Regularmente, durante el día\n',
      THIRD_BUTTON_DOG: 'Un paseo al día',
      TITLE: '¿Cuál es su perfil de actividad?'
    },
    PET_AGE: {
      FIRST_COMMON_TEXT: 'año(s) y ',
      SECOND_COMMON_TEXT: 'mes(es)',
      TITLE: '¿Cuál es su fecha de nacimiento?'
    },
    PET_GENDER: {
      FEMALE: 'Hembra',
      FIRST_TITLE: '¿Tu mascota es una hembra o un macho?',
      MALE: 'Macho',
      SECOND_TITLE: '¿Tu mascota está esterilizada?'
    },
    PET_HEALTH: {
      LAST_VACINATION: '¿Cuándo se vacunó por última vez?',
      LAST_VET_VISIT: '¿Cuándo fue su última visita al veterinario?',
      TITLE: 'Punto de Salud'
    },
    PET_IDENTIFICATION: {
      CHIP: 'Chip',
      NONE: 'Mi mascota no está identificada',
      SELECT_IDENTIFICATION: '¿Cómo se identifica?',
      TATTOO: 'Tatuado'
    },
    PET_INFO: {
      GET_STARTED: '¿Empezamos?',
      NEED_TO_KNOW: 'Para nosotros es importante aprender a conocer a {{name}}.',
      PETS_DETAILS: '¡Completa los datos de tu mascota!',
      PETS_GENDER: {
        MIXED_BREED: 'Es un cruzado'
      },
      PET_GENDER_COMMON: 'Para empezar, tu mascota es: ',
      SECOND_PETS_DETAILS: 'Rellena los datos de tu compañero'
    },
    PET_MORPH: {
      AVERAGE: 'Normal',
      COMMON_TEXT: 'Aproximadamente',
      FIRST_TITLE: '¿Qué tipo de constitución tiene?',
      HELP_TEXT: '¿Cómo puedo definir la morfología de mi mascota?\n',
      LEAN: 'Delgado',
      OBESE: 'Obeso',
      OVERWEIGHT: 'Con sobrepeso',
      SECOND_TITLE: '¿Cuánto pesa?',
      WEIGH_TEXT: '¿Cuál es el peso de su mascota?\n'
    },
    PET_NAME: {
      COMMON_TEXT: 'Nos gustaría conocer a tu mascota.\u2028 Después, podrás modificar esta información en los ajustes. ',
      PLACEHOLDER: 'Nombre de su mascota',
      TITLE: '¿Cómo se llama?'
    },
    PET_RACE: {
      COMMON_TEXT: 'No sé de qué raza es',
      PLACEHOLDER: 'Raza de su perro',
      SELECT_BREED: 'Seleccionar la raza',
      TITLE: '¿Cuál es la raza de tu mascota? '
    },
    PET_TYPE: {
      CAT: 'Un gato',
      DOG: 'Un perro'
    },
    SUMMARY: {
      BIRTH_DATE: 'Fecha de nacimiento',
      BREED: 'Raza',
      CONDITION: 'Estado',
      DAILY_EXERCISE: 'Gasto diario',
      DELETE: 'Restablecer perfil',
      HEALTH: 'Salud',
      IDENTIFICATION: 'Identificación',
      MODAL: {
        NO: 'No, anular',
        SUBTEXT: 'Entonces tendrás que volver a empezar el proceso.',
        TITLE: '¿Estás seguro que quieres restablecer la información de su mascota?',
        YES: 'Si soy seguro'
      },
      MORPHOLOGY: 'Morfología',
      NAME: 'Nombre',
      RECAPTEXT: 'Aquí está la información sobre su compañero.',
      SEX: 'Sexo',
      SPECIES: 'Especies',
      SUBMIT: 'Validar el perfil',
      UNSPECIFIED: 'faltante',
      VALIDATION: 'Verificación del perfil',
      VALIDATION_TEXT: 'Por favor, valida yu información antes de continuar. ',
      WEIGHT: 'Peso'
    }
  },
  activityTracking: {
    ACTIVITY: 'de actividad',
    ACTIVITY_DISTRIBUTION: {
      DATA_COMPARATOR: {
        ACTIVITY_INTENSE: 'Actividad intensa',
        ACTIVITY_INTENSE_LEGEND: 'de actividad intensa {{day}}',
        ACTIVITY_MODERATE: 'Actividad moderada',
        ACTIVITY_MODERATE_LEGEND: 'de actividad moderada {{day}}',
        ACTIVITY_WEAK: 'Actividad baja',
        ACTIVITY_WEAK_LEGEND: 'de actividad baja {{day}}',
        TEXT: 'Ayer, {{petName}} tuvo una actividad {{activity}} {{comparator}} {{day}}.'
      }
    },
    ACTIVITY_REPORT: {
      DATA_COMPARATOR: {
        LEGEND: {
          KM_MONTHLY: 'kilómetros diarios en promedio este mes',
          KM_WEEKLY: 'kilómetros diarios en promedio esta semana',
          KM_YESTERDAY: 'kilómetros recorridos ayer',
          STEP_MONTHLY: 'pasos diarios en promedio este mes',
          STEP_WEEKLY: 'pasos diarios en promedio esta semana',
          STEP_YESTERDAY: 'pasos dados ayer'
        },
        TEXT_INF: 'inferior',
        TEXT_KM_DAILY: 'La cantidad de kilómetros recorridos ayer por {{petName}} es {{comparator}} a la del día anterior.',
        TEXT_KM_MONTHLY: 'La cantidad promedio de kilómetros recorridos esta semana por {{petName}} es {{comparator}} a su promedio mensual.',
        TEXT_KM_WEEKLY: 'La cantidad de kilómetros recorridos ayer por {{petName}} es {{comparator}} a su promedio semanal.',
        TEXT_SIM: 'similar',
        TEXT_STEP_DAILY: 'La cantidad de pasos dados ayer por {{petName}}  es {{comparator}} a la del día anterior.',
        TEXT_STEP_MONTHLY: 'La media de pasos esta semana de {{petName}} es {{comparator}} a la del mes actual.',
        TEXT_STEP_WEEKLY: 'La cantidad de pasos dados ayer por {{petName}} es {{comparator}} a su promedio semanal.',
        TEXT_SUP: 'superior',
        TITLE_KM_TRAVELED: 'Kilómetros recorridos',
        TITLE_STEP: 'Pasos realizados'
      },
      KM_TRAVELED: '{{step}} kilómetros recorridos'
    },
    AIMED_GOAL: {
      ALERT: 'Recomendamos un objetivo diario de al menos 3 horas para mantener a su mascota saludable.',
      TITLE: 'Establece un objetivo de actividad para alcanzar cada día.'
    },
    DAILY_CHART: 'Actividad de {{petName}}',
    DAILY_GOAL: {
      NOT_RECOMMENDED: 'Objetivo no recomendado',
      RECOMMENDED: 'Objetivo recomendado',
      SECOND_TITLE: 'Objetivo diario',
      TITLE: 'Objetivo diario'
    },
    DAILY_STEP: 'Pasos diarios',
    DAYS: 'Día',
    EQUAL: 'similar a',
    ERROR_PAGE: {
      BUTTON_TEXT: 'Cerrar la página',
      TEXT: 'Actualmente no podemos cargar la página. Por favor, inténtelo más tarde.',
      TITLE: 'Ups, hemos encontrado un problema...'
    },
    HEADER: {
      ACTIVITY_DISTRIBUTION: {
        TITLE: 'Distribución de la actividad'
      },
      ACTIVITY_REPORT: {
        TITLE: 'Informes de actividad'
      },
      GOAL: {
        TITLE: 'Objetivo diario'
      }
    },
    HOME: {
      ACTIVITY_DISTRIBUTION: {
        INTENSE: 'Intenso',
        MODERATE: 'Moderado',
        WEAK: 'Bajo'
      },
      ALERT: 'Se necesitan unos días de aprendizaje para garantizar datos fiables. Durante este periodo, el algoritmo recopila y analiza sus datos para entender los hábitos y patrones de actividad de su mascota. Con el tiempo, los resultados serán cada vez más precisos y ajustados a su perfil.',
      FIRST_SECTION: {
        TITLE: 'Objetivo diario'
      },
      INSURANCE: {
        BUTTON: 'Descubrir Weenect Seguro',
        TEXT: 'Es necesario visitar al veterinario. Confíe en Weenect para el reembolso de tu consulta.'
      },
      MODIFY: 'Modificar',
      PROFILE: {
        SUBTITLE: 'Controle su actividad diaria para mantenerlo en forma.'
      },
      SECOND_SECTION: {
        SUBTITLE: 'Durante el día en curso.',
        TITLE: 'Distribución'
      },
      SEE_MORE: 'Ver más',
      THIRD_SECTION: {
        TITLE: 'Puntos clave'
      }
    },
    HOUR: '{{count}} h',
    HOURS: 'Horas',
    INTERVAL: {
      TEXT: 'Mueva los bordes del gráfico para ajustar el periodo visualizado.',
      TITLE: 'Selección del período'
    },
    KEY_POINTS: 'Puntos clave',
    KILOMETERS: 'Kilómetros',
    LESS: 'más breve que',
    MIN: 'Minutos',
    MINUTE_ACTIVITY_one: '{{count}} minuto de actividad',
    MINUTE_ACTIVITY_other: '{{count}} minutos de actividad',
    MINUTE_one: '{{count}} minuto',
    MINUTE_other: '{{count}} minutos',
    MIN_one: '{{count}} min',
    MIN_other: '{{count}} min',
    MONTHLY_STEP: 'Pasos mensuales',
    ONBOARDING: {
      FIRST_STEP: {
        ALERT: 'En modo avión, la actividad sigue registrándose. Aparecerá en el panel de instrumentos en cuanto abandones la zona wifi.',
        BUTTON: 'Empezar',
        MESSAGE: 'Esta herramienta te permite hacer un seguimiento diario de tu compañero peludo. ¿Cuánto tiempo ha dormido? ¿Cuántos pasos ha hecho hoy? Todas estas cosas dejarán de ser un secreto para ti.',
        TITLE: 'Este es el seguimiento de actividad de tu mascota.'
      },
      SECONDE_STEP: {
        BUTTON: 'Guardar',
        PROFILE: {
          SUBTITLE: 'Controla su actividad diaria para mantenerlo en forma.',
          TITLE: 'Actividad de {{petName}}'
        },
        TITLE: 'Establece un objetivo de actividad para alcanzar cada día.'
      }
    },
    PERIOD: {
      DAY: 'Día',
      MONTH: 'Mes',
      TITLE: 'Selección del período',
      WEEK: 'Semana',
      YEAR: 'Año'
    },
    PLUS: 'más larga que',
    RETURN_HOME: 'Volver al inicio',
    STEP: 'Pasos',
    STEP_SAVED: '{{step}} pasos',
    TEXT: 'Este gráfico le indica el desarrollo de la actividad de su mascota. Esta actividad se mide en un intervalo que comienza en 0 y no tiene límite máximo. Cada punto de actividad representa una aceleración, es decir, un movimiento de su mascota.',
    TOTAL: 'Total',
    WEEKDAYS: {
      FRIDAY: 'Viernes',
      FRIDAY_COMPARATIVE: 'el viernes',
      MONDAY: 'Lunes',
      MONDAY_COMPARATIVE: 'el lunes',
      SATURDAY: 'Sábado',
      SATURDAY_COMPARATIVE: 'el sábado',
      SUNDAY: 'Domingo',
      SUNDAY_COMPARATIVE: 'el domingo',
      THURSDAY: 'Jueves',
      THURSDAY_COMPARATIVE: 'el jueves',
      TUESDAY: 'Martes',
      TUESDAY_COMPARATIVE: 'el martes',
      WEDNESDAY: 'Miércoles',
      WEDNESDAY_COMPARATIVE: 'el miércoles'
    },
    WEEKLY_STEP: 'Pasos semanales',
    YESTERDAY: 'ayer'
  },
  alertCustomer: {
    BODY0: 'Nuestro proveedor de tarjeta SIM está actualmente en mantenimiento.',
    BODY1: 'Durante este periodo de mantenamiento <1>el tiempo de espera para la activación de una SIM ha sido extendido a 24h</1>.',
    BODY2: 'Somos muy conscientes de las molestias que podría causar. Sentimos mucho esta situación fuera de nuestro control.\n\nPara más información, puede ponerse en contacto con nuestro equipo en <1>family@weenect.com</1> o por teléfono en al +442070391906.',
    FOOTER: 'Equipo Weenect'
  },
  animalInfo: {
    LANDING_PAGE: {
      BUTTON: 'Empezar',
      TEXT: 'Conozcamos a tu compañero. Podrás modificar los datos más tarde en los ajustes. ',
      TITLE: '¡Ingrese la información de su mascota!',
      WARNING: 'Esta información es necesaria para el seguimiento de actividad más preciso y para personalizar su experiencia Weenect.'
    },
    NEXT_BUTTON: 'Siguiente',
    PROFIL_VALIDATION: {
      ACTIVITY: 'Actividad y comportamiento',
      BUTTON: 'Validar el perfil',
      INDENTIFICATION_INFOS: 'Información de identificación',
      MEDICAL_HISTORY: 'Historial médico',
      PERSONAL_INFOS: 'Información personal',
      PHYSICAL: 'Características físicas',
      SEX: 'Sexo',
      SPECIES: 'Especie',
      TITLE: 'Validación del perfil',
      UNIQUE_IDENTIFICATION: 'Identificación única'
    },
    QUESTION: 'Pregunta',
    QUESTION_EIGHT: {
      NO: 'No',
      NOT_STERILIZED: 'No esterilizado',
      QUESTION: '¿Tu mascota está esterilizada?',
      STERILIZATION_STATUS: 'Estado de esterilización',
      STERILIZED: 'Esterilizado',
      YES: 'Sí'
    },
    QUESTION_ELEVEN: {
      EVERYTIME: 'A menudo',
      EXIT_FREQUENCY: 'Frecuencia de salidas',
      MOSTLY: 'A veces',
      OFTEN: 'Con regularidad',
      QUESTION: '¿Con qué frecuencia sale?',
      RARELY: 'Raramente'
    },
    QUESTION_FIVE: {
      QUESTION: '¿Cuánto pesa tu mascota?',
      WEIGHT: 'Peso'
    },
    QUESTION_FOUR: {
      FEMALE: 'Hembra',
      MALE: 'Macho',
      QUESTION: '¿Cuál es el sexo de su mascota?'
    },
    QUESTION_NINE: {
      LAST_VISIT: 'última visita',
      QUESTION: '¿Cuándo fue su última visita al veterinario?'
    },
    QUESTION_ONE: {
      CAT: 'Un gato',
      DOG: 'Un perro',
      QUESTION: '¿Cuál es el perfil de su mascota?'
    },
    QUESTION_SEVEN: {
      CHIPED: 'Microchip',
      IDENTIFICATION: 'Identificación',
      NOT_IDENTIFIED: 'Mi mascota no está identificada',
      QUESTION: '¿Qué identificación tiene?',
      TATTOED: 'Tatuaje'
    },
    QUESTION_SIX: {
      QUESTION: '¿Cuál es la raza de su mascota?',
      RACE: 'Raza'
    },
    QUESTION_TEN: {
      LAST_VACCIN: 'última vacuna',
      QUESTION: '¿Y su última vacuna?'
    },
    QUESTION_THREE: {
      BIRTHDATE: 'Fecha de nacimiento',
      QUESTION: '¿Cuál es su fecha de nacimiento?'
    },
    QUESTION_TWO: {
      NAME: 'Nombre',
      QUESTION: '¿Cómo se llama?'
    }
  },
  breed: {
    CAT: {
      ABYSSINIAN: 'Abisinio',
      AMERICAN_BOBTAIL: 'Americano Bobtail',
      AMERICAN_CURL: 'Curl americano',
      AMERICAN_SHORTHAIR: 'Americano de pelo corto',
      AMERICAN_WIREHAIR: 'Americano de pelo duro',
      BALINESE: 'Balinés',
      BENGAL: 'Bengalí',
      BIRMAN: 'Birmano',
      BOMBAY: 'Bombay',
      BURMESE: 'Burmés',
      BURMILLA: 'Burmilla',
      CALIFORNIA_SPANGLED_CAT: 'California Spangled',
      CHANTILLYTIFFANY: 'Chantilly-Tiffany',
      CHARTREUX: 'Cartujo',
      CORNISH_REX: 'Cornish Rex',
      CYMRIC: 'Cymric',
      DEVON_REX: 'Devon Rex',
      EGYPTIAN_MAU: 'Mau egipcio',
      EUROPEAN: 'Europeo',
      EXOTIC_SHORTHAIR: 'Exótico de pelo corto',
      HAVANA: 'Habana',
      JAPANESE_BOBTAIL: 'Bobtail japonés',
      JAVANESE: 'Javanés',
      KORAT: 'Korat',
      MAINE_COON: 'Maine Coon',
      MALAYAN: 'Malayo',
      MANX: 'Manx',
      MUNCHKIN: 'Munchkin',
      NEBELUNG: 'Nebelung',
      NORWEGIAN: 'Bosque de Noruega',
      NORWEGIAN_FOREST_CAT: 'Bosque de Noruega',
      OCICAT: 'Ocicat',
      ORIENTAL_LONGHAIR: 'Oriental de pelo largo',
      ORIENTAL_SHORTHAIR: 'Oriental',
      PERSIAN: 'Persa',
      RAGDOLL: 'Ragdoll',
      RUSSIAN_BLUE: 'Azul ruso',
      SAFARI_CAT: 'Safari',
      SCOTTISH_FOLD: 'Fold escocés',
      SELKIRK: 'Selkirk rex',
      SIAMESE: 'Siamés',
      SIBERIAN: 'Siberiano',
      SINGAPURA: 'Singapur',
      SNOWSHOE: 'Snowshoe',
      SOMALI: 'Somalí',
      SPHYNX: 'Sphynx',
      THAI: 'Tailandés',
      TONKINESE: 'Tonkinés',
      TURKISH_ANGORA: 'Angora Turco',
      TURKISH_VAN: 'Van Turco'
    },
    DOG: {
      AFFENPINSCHER: 'Affenpinscher',
      AFGHAN_HOUND: 'Galgo afgano',
      AIREDALE_TERRIER: 'Airedale Terrier',
      AKITA: 'Akita Inu',
      AKITA_INU_GREAT_JAPANESE_DOG: 'Gran perro japonés',
      ALASKAN_MALAMUTE: 'Malamute de Alaska',
      ALPINE_DACHSBRACKE: 'Tejonero de los Alpes',
      AMERICAN_BULLDOG: 'Bulldog americano',
      AMERICAN_COCKER_SPANIEL: 'Cocker Spaniel americano',
      AMERICAN_FOXHOUND: 'Foxhound americano',
      AMERICAN_STAFFORDSHIRE_TERRIER: 'American Staffordshire Terrier',
      AMERICAN_WATER_SPANIEL: 'Perro de agua americano',
      ANATOLIAN_SHEPHERD_DOG: 'Pastor de Anatolia',
      APPENZELLER_SENNENHUND: 'Boyero de Appenzell',
      ARDENNES_CATTLE_DOG: 'Boyero de las Ardenas',
      ARGENTINE_DOGO: 'Dogo argentino',
      ARIEGEOIS: 'Sabueso del Ariège',
      ARIEGE_POINTER: 'Braco de Ariege',
      ARTOIS_HOUND: 'Sabueso artesiano',
      ATLAS_SHEPHERD_DOG_AIDI: 'Perro del Atlas (Aïdi)',
      AUSTRALIAN_CATTLE_DOG: 'Pastor ganadero australiano',
      AUSTRALIAN_KELPIE: 'Kelpie australiano',
      AUSTRALIAN_SHEPHERD: 'Pastor australiano miniatura',
      AUSTRALIAN_TERRIER: 'Terrier de Australia',
      AUSTRIAN_BLACK_AND_TAN_HOUND: 'Sabueso austríaco negro y fuego',
      AUSTRIAN_SHORTHAIRED_PINSCHER: 'Pinscher austriaco de pelo corto',
      AZAWAKH: 'Azawakh',
      BALKAN_HOUND: 'Sabueso de los Balcanes',
      BARBET: 'Barbet',
      BASENJI: 'Basenji',
      BASSET_ARTESIEN_NORMAND: 'Basset artesiano de Normandía',
      BASSET_BLEU_DE_GASCOGNE: 'Basset azul de Gascuña',
      BASSET_FAUVE_DE_BRETAGNE: 'Basset leonardo de Bretaña',
      BASSET_HOUND: 'Basset Hound',
      BAVARIAN_MOUNTAIN_SCENTHOUND: 'Sabueso de sangre de Baviera',
      BEAGLE: 'Beagle',
      BEARDED_COLLIE: 'Collie barbudo',
      BEAUCERON: 'Beauceron',
      BEDLINGTON_TERRIER: 'Bedlington terrier',
      BELGIAN_GRIFFON: 'Grifón belga',
      BELGIAN_SHEPHERD_DOG_GROENENDAEL: 'Pastor belga groenendael',
      BELGIAN_SHEPHERD_DOG_LAEKENOIS: 'Pastor belga laekenois',
      BELGIAN_SHEPHERD_DOG_MALINOIS: 'Pastor belga malinois',
      BELGIAN_SHEPHERD_DOG_TERVUREN: 'Pastor belga tervueren',
      BERGAMASCO_SHEPHERD: 'Pastor de Bérgamo',
      BERNER_NIEDERLAUFHUND: 'Pequeño sabueso de Berna',
      BERNESE_HOUND: 'Sabueso de Berna',
      BERNESE_MOUNTAIN_DOG: 'Boyero de Berna',
      BICHON_FRISE: 'Bichón frisé',
      BILLY_DOG: 'Billy',
      BLACK_AND_TAN_COONHOUND: 'Coonhound negro y fuego',
      BLOODHOUND_SAINTHUBERT_DOG: 'Perro de San Huberto',
      BLUE_PICARDY_SPANIEL: 'Spaniel azul de Picardía',
      BOHEMIAN_WIREHAIRED_POINTING_GRIFFON: 'Grifón de muestra bohemio',
      BOLOGNESE_DOG: 'Bichón boloñés',
      BORDEAUX_MASTIFF_FRENCH_MASTIFF: 'Dogo de Burdeos',
      BORDER_COLLIE: 'Border Collie',
      BORDER_TERRIER: 'Border Terrier',
      BORZOI: 'Borzoi',
      BOSNIAN_COARSEHAIRED_HOUND: 'Sabueso bosnio',
      BOSTON_TERRIER: 'Terrier de Boston',
      BOURBONNAIS_POINTER: 'Braco de Borbón',
      BOUVIER_DES_FLANDRES: 'Boyero de Flandes',
      BOXER_DOG: 'Bóxer',
      BRAQUE_D_AUVERGNE: 'Braco de Auvernia',
      BRAZILIAN_TERRIER: 'Terrier brasileño',
      BRIARD: 'Pastor de Brie',
      BRIQUET_GRIFFON_VENDEEN: 'Grifón vandeano pequeño',
      BRITTANY_SPANIEL: 'Spaniel bretón',
      BROHOLMER: 'Broholmer',
      BRUNO_JURA_HOUND: 'Pequeño sabueso del Jura',
      BRUNO_JURA_HOUND_BRUNO_TYPE: 'Bruno del Jura',
      BRUNO_JURA_HOUND_ST_HUBERT_TYPE: 'Bruno del Jura tipo San Huberto',
      BRUSSELS_GRIFFON: 'Grifón de Bruselas',
      BULLMASTIFF: 'Bullmastiff',
      BULL_TERRIER_ENGLISH_BULL_TERRIER: 'Bull Terrier',
      BURGOS_POINTER: 'Perdiguero de Burgos',
      CAIRN_TERRIER: 'Cairn Terrier',
      CANAAN_DOG: 'Perro de Canaán',
      CANARY_ISLAND_PODENCO: 'Podenco canario',
      CANE_CORSO: 'Mastín italiano',
      CAO_DE_CASTRO_LABOREIRO: 'Castro Laboreiro',
      CAO_FILA_DE_SAO_MIGUEL: 'Fila de São Miguel',
      CATAHOULA_LEOPARD_DOG: 'Leopardo de Catahoula',
      CATALAN_SHEEPDOG: 'Pastor catalán',
      CAUCASIAN_SHEPHERD_DOG: 'Pastor caucásico',
      CAVALIER_KING_CHARLES_SPANIEL: 'Cavalier King Charles',
      CA_DE_BOU_PERRO_DE_PRESA: 'Perro de Presa mallorquín',
      CENTRAL_ASIAN_SHEPHERD_DOG: 'Pastor de Asia Central',
      CESKY_TERRIER: 'Terrier checo',
      CHESAPEAKE_BAY_RETRIEVER: 'Retriever de Chesapeake',
      CHIHUAHUA: 'Chihuahua',
      CHINESE_CRESTED_DOG: 'Crestado chino',
      CHOW_CHOW: 'Chow chow',
      CIRNECO_DELL_ETNA: 'Cirneco del Etna',
      CLUMBER_SPANIEL: 'Clumber',
      CONTINENTAL_TOY_SPANIEL: 'Spaniel continental enano',
      CORSICAN_DOG: 'Cursinu',
      COTON_DE_TULEAR: 'Cotón de Tuléar',
      CROATIAN_SHEEPDOG: 'Pastor croata',
      CURLYCOATED_RETRIEVER: 'Retriever de pelo rizado',
      CZECHOSLOVAKIAN_WOLFDOG: 'Perro lobo checoslovaco',
      DACHSHUND_LONGHAIRED: 'Teckel de pelo largo',
      DACHSHUND_LONGHAIRED_RABBIT: 'Teckel de caza (pelo largo)',
      DACHSHUND_SMOOTHHAIRED: 'Teckel de pelo raso',
      DACHSHUND_SMOOTHHAIRED_RABBIT: 'Teckel de caza (pelo raso)',
      DACHSHUND_WIREHAIRED: 'Teckel de pelo duro',
      DACHSHUND_WIREHAIRED_RABBIT: 'Teckel de caza (pelo duro)',
      DALMATIAN: 'Dálmata',
      DANDIE_DINMONT_TERRIER: 'Terrier Dandie Dinmont',
      DOBERMAN_PINSCHER: 'Dóberman',
      DOGO_CANARIO: 'Presa canario',
      DOGUE_DE_BORDEAUX: 'León de Occitania',
      DRENTSE_PARTRIDGE_DOG: 'Perdiguero de Drente',
      DREVER: 'Drever',
      DUNKER: 'Dunker',
      DUPUY_POINTER: 'Braco de Puy',
      DUTCH_SHEPHERD_LONGHAIRED_VARIETY: 'Pastor holandés de pelo largo',
      DUTCH_SHEPHERD_ROUGHHAIRED_VARIETY: 'Pastor holandés de pelo duro',
      DUTCH_SHEPHERD_SHORTHAIRED_VARIETY: 'Pastor holandés de pelo corto',
      DUTCH_SMOUSHOND: 'Pequeño perro holandés para la caza',
      EAST_SIBERIAN_LAIKA: 'Laika de Siberia Oriental',
      ENGLISH_BULLDOG: 'Bulldog inglés',
      ENGLISH_COCKER_SPANIEL: 'Cocker Spaniel inglés',
      ENGLISH_POINTER: 'Pointer inglés',
      ENGLISH_SETTER: 'Setter inglés',
      ENGLISH_SPRINGER_SPANIEL: 'Springer Spaniel inglés',
      ENGLISH_TOY_TERRIER: 'Toy terrier negro y fuego',
      ENTLEBUCHER_MOUNTAIN_DOG: 'Boyero de Entlebuch',
      EURASIER: 'Eurasier',
      FAWN_BRITTANY_BASSET: 'Grifón leonado de Bretaña',
      FIELD_SPANIEL: 'Spaniel de campo',
      FILA_BRASILEIRO: 'Fila Brasileiro',
      FINNISH_HOUND: 'Sabueso finlandés',
      FINNISH_LAPPHUND: 'Perro finlandés de Laponia',
      FINNISH_SPITZ: 'Spitz finlandés',
      FLATCOATED_RETRIEVER: 'Retriever de pelo liso',
      FOXHOUND_ENGLISH_FOXHOUND: 'Foxhound inglés',
      FRENCH_BULLDOG: 'Bulldog francés',
      FRENCH_SPANIEL: 'Spaniel francés',
      FRENCH_WHITE_AND_BLACK_HOUND: 'Sabueso francés blanco y negro',
      FRENCH_WHITE_AND_ORANGE_HOUND: 'Sabueso francés blanco y naranja',
      FRISIAN_WATER_DOG: 'Perro de agua frisón',
      GASCONY_POINTER: 'Braco francés tipo Gascuña',
      GERMAN_LONGHAIRED_POINTER: 'Perro de muestra alemán de pelo largo',
      GERMAN_PINSCHER: 'Pinscher mediano',
      GERMAN_SHEPHERD: 'Pastor alemán',
      GERMAN_SHORTHAIRED_POINTER: 'Braco alemán de pelo corto',
      GERMAN_SPITZ: 'Spitz alemán (grande)',
      GERMAN_WIREHAIRED_POINTER: 'Braco alemán de pelo grueso',
      GIANT_SCHNAUZER: 'Schnauzer gigante',
      GLEN_OF_IMAAL_TERRIER: 'Terrier Glen de Imaal irlandés',
      GOLDEN_RETRIEVER: 'Golden Retriever',
      GORDON_SETTER: 'Setter escocés',
      GOTH_SHEPHERD_DOG: 'Spitz de los Visigodos',
      GRAND_ANGLOFRANCAIS_BLANC_ET_NOIR: 'Gran sabueso anglo-francés blanco y negro',
      GRAND_ANGLOFRANCAIS_BLANC_ET_ORANGE: 'Gran sabueso anglo-francés blanco y naranja',
      GRAND_ANGLOFRANCAIS_TRICOLORE: 'Gran sabueso anglo-francés  tricolor',
      GRAND_BASSET_GRIFFON_VENDEEN: 'Gran basset grifón vandeano',
      GRAND_BLEU_DE_GASCOGNE: 'Gran azul de Gascuña',
      GRAND_GASCONSAINTONGEOIS_HOUND: 'Sabueso de Saintonge',
      GRAND_GRIFFON_VENDEEN: 'Gran grifón vandeano',
      GRAND_SPANISH_HOUND: 'Sabueso español (grande)',
      GREATER_SWISS_MOUNTAIN_DOG: 'Gran boyero suizo',
      GREAT_DANE: 'Gran danés',
      GREAT_PYRENEES: 'Perro de montaña de los Pirineos',
      GREENLAND_DOG: 'Esquimal de Groenlandia',
      GREYHOUND: 'Greyhound',
      HALDEN_HOUND: 'Sabueso de Halden',
      HAMILTONSTOVARE: 'Sabueso de Hamilton',
      HANOVERIAN_SCENTHOUND: 'Sabueso de Hannover',
      HANOVER_HOUND: 'Sabueso alemán de pelo corto',
      HARRIER: 'Beagle-Harrier',
      HAVANESE_DOG: 'Bichón habanero',
      HELLENIC_HOUND: 'Sabueso griego',
      HOKKAIDO: 'Hokkaido',
      HOVAWART: 'Hovawart',
      HUNGARIAN_GREYHOUND: 'Galgo húngaro',
      HUNGARIAN_KUVASZ: 'Kuvasz',
      HYGEN_HOUND: 'Sabueso de Hygen',
      IBIZAN_HOUND_SHORT_HAIRED: 'Podenco ibicenco de pelo corto',
      IBIZAN_HOUND_WIRE_HAIRED: 'Podenco ibicenco de pelo duro',
      ICELANDIC_SHEEPDOG: 'Pastor islandés',
      IRISH_RED_AND_WHITE_SETTER: 'Setter irlandés rojo',
      IRISH_SETTER: 'Setter irlandés rojo y blanco',
      IRISH_TERRIER: 'Terrier irlandés',
      IRISH_WATER_SPANIEL: 'Perro de agua irlandés',
      IRISH_WOLFHOUND: 'Galgo irlandés',
      ISTRIAN_COARSEHAIRED_HOUND: 'Sabueso de Istria de pelo duro',
      ISTRIAN_SHORTHAIRED_HOUND: 'Sabueso de Istria de pelo raso',
      ITALIAN_GREYHOUND: 'Pequeño galgo italiano',
      ITALIAN_POINTING_DOG: 'Braco italiano',
      ITALIAN_SHORTHAIRED_HOUND: 'Sabueso italiano de pelo corto',
      ITALIAN_SPITZ: 'Volpino italiano',
      ITALIAN_WIREHAIRED_HOUND: 'Sabueso italiano de pelo duro',
      JACK_RUSSELL_TERRIER: 'Jack Russell Terrier',
      JAGDTERRIER: 'Terrier alemán',
      JAPANESE_SPANIEL_CHIN: 'Spaniel japonés',
      JAPANESE_SPITZ: 'Spitz japonés',
      JAPANESE_TERRIER: 'Terrier japonés',
      KAI_KEN: 'Kai',
      KARELIAN_BEAR_DOG: 'Perro de osos de Carelia',
      KARST_SHEPHERD: 'Pastor de Karst',
      KERRY_BLUE_TERRIER: 'Kerry blue Terrier',
      KISHU_KEN: 'Kishu Inu',
      KOMONDOR: 'Komondor',
      KOREAN_JINDO_DOG: 'Perro de Chindo',
      KROMFOHRLANDER: 'Kromfohrlander',
      KYILEO: 'Kyi-Leo',
      LABRADOR: 'Labrador',
      LAKELAND_TERRIER: 'Lakeland Terrier',
      LANDSEER: 'Landseer',
      LAPPONIAN_HERDER: 'Pastor finlandés de Laponia',
      LARGE_MUNSTERLANDER: 'Munsterlander grande',
      LEONBERGER: 'Leonberger',
      LHASA_APSO: 'Lhasa Apso',
      LOWCHEN_LITTLE_LION_DOG: 'Pequeño perro león (Lowchen)',
      LUCERNE_HOUND: 'Sabueso de Lucerna',
      LUZERNER_LAUFHUND: 'Pequeño sabueso de Lucerna',
      MAJORCA_SHEPHERD_DOG: 'Pastor mallorquín',
      MALTESE_DOG: 'Bichón maltés',
      MANCHESTER_TERRIER: 'Terrier de Manchester',
      MAREMMA_SHEEPDOG: 'Pastor de Maremma',
      MASTIFF_FRENCH_MASTIFF: 'Mastín',
      MEDIUM_POODLE: 'Caniche (mediano)',
      MEXICAN_HAIRLESS_DOG: 'Xoloescuincle',
      MINIATURE_BULL_TERRIER: 'Bull Terrier miniatura',
      MINIATURE_DACHSHUND_LONGHAIRED: 'Teckel miniatura de pelo largo',
      MINIATURE_DACHSHUND_SMOOTHHAIRED: 'Teckel miniatura de pelo raso',
      MINIATURE_DACHSHUND_WIREHAIRED: 'Teckel miniatura de pelo duro',
      MINIATURE_PINSCHER: 'Pinscher miniatura',
      MINIATURE_POODLE: 'Caniche (pequeño)',
      MINIATURE_SCHNAUZER: 'Schnauzer miniatura',
      MIXED_BREED_CROSSBREED: 'Mestizo',
      MUDI: 'Mudi',
      NEAPOLITAN_MASTIFF: 'Mastín napolitano',
      NEWFOUNDLAND: 'Terranova',
      NORFOLK_TERRIER: 'Terrier de Norfolk',
      NORRBOTTENSPETS: 'Spitz de Norrbotten',
      NORWEGIAN_BUHUND: 'Buhund noruego',
      NORWEGIAN_ELKHOUND_BLACK: 'Cazador de alces noruego negro',
      NORWEGIAN_ELKHOUND_GRAY: 'Cazador de alces noruego gris',
      NORWEGIAN_LUNDEHUND: 'Lundehund noruego',
      NORWICH_TERRIER: 'Terrier de Norwich',
      NOVA_SCOTIA_DUCK_TOLLING_RETRIEVER: 'Retriever de Nueva Escocia',
      OLD_DANISH_POINTER: 'Antiguo perro de muestra danés',
      OLD_ENGLISH_SHEEPDOG: 'Bobtail',
      OTTERHOUND: 'Otterhound',
      PARSON_RUSSELL_TERRIER: 'Parson Russell Terrier',
      PEKINGESE: 'Pequinés',
      PERUVIAN_HAIRLESS_DOG_LARGE: 'Viringo peruano (grande)',
      PERUVIAN_HAIRLESS_DOG_MEDIUM: 'Viringo peruano (mediano)',
      PERUVIAN_HAIRLESS_DOG_SMALL: 'Viringo peruano (pequeño)',
      PETIT_BASSET_GRIFFON_VENDEEN: 'Sabueso anglofrancés de caza menor',
      PETIT_BRABANCON: 'Pequeño brabanzón',
      PETIT_GASCON_SAINTONGEOIS: 'Pequeño sabueso de Saintonge',
      PHARAOH_HOUND: 'Podenco faraónico',
      PICARDY_SHEPHERD: 'Pastor de Picardía',
      PICARDY_SPANIEL: 'Spaniel de Picardía',
      POITEVIN: 'Poitevino',
      POLISH_HOUND: 'Sabueso polaco',
      POLISH_LOWLAND_SHEEPDOG: 'Pastor polaco de tierras bajas',
      PONTAUDEMER_SPANIEL: 'Spaniel de Pont-Audemer',
      PORCELAINE: 'Porcelana',
      PORTUGUESE_PODENGO_GRANDE: 'Podenco portugués grande',
      PORTUGUESE_PODENGO_MEDIO: 'Podenco portugués mediano',
      PORTUGUESE_PODENGO_PEQUENO: 'Podenco portugués pequeño',
      PORTUGUESE_POINTER: 'Perdiguero portugués',
      PORTUGUESE_SHEEPDOG: 'Pastor portugués',
      PORTUGUESE_SHEEPDOG_LONGHAIRED: 'Perro de Montaña de la Sierra de la Estrella (pelo ondulado)',
      PORTUGUESE_SHEEPDOG_SHORTHAIRED: 'Perro de Montaña de la Sierra de la Estrella (pelo corto)',
      PORTUGUESE_WATER_DOG: 'Perro de agua portugués',
      POSAVAC_HOUND: 'Sabueso de Posavatz',
      PRAGUE_RATTER: 'Ratonero de Praga',
      PUDEL_POINTER: 'Pudelpointer',
      PUG: 'Carlino',
      PULI_HUNGARIAN_SHEPHERD: 'Puli',
      PUMI_HUNGARIAN_SHEPHERD: 'Pumi',
      PYRENEAN_MOUNTAIN_DOG: 'Mastín del Pirineo',
      PYRENEAN_POINTER: 'Braco francés tipo Pirineos',
      PYRENEAN_SHEEPDOG_LONGFACED_VARIETY: 'Pastor de los Pirineos de pelo largo',
      PYRENEAN_SHEEPDOG_SMOOTHFACED_VARIETY: 'Pastor de los Pirineos de cara rasa',
      RAFEIRO_DE_ALENTEJO: 'Mastín de Alentejo',
      REDBONE_COONHOUND: 'Redbone Coonhound',
      REDTICK_COONHOUND: 'Redtick Coonhound',
      RHODESIAN_RIDGEBACK: 'Perro crestado rodesiano',
      ROMAGNA_WATER_DOG: 'Perro de agua Romagnolo',
      ROTTWEILER: 'Rottweiler',
      ROUGH_COLLIE: 'Collie de pelo largo',
      RUSSIAN_BLACK_TERRIER: 'Terrier ruso negro',
      RUSSOEUROPEAN_LAIKA: 'Laika ruso-europeo',
      SAARLOOSWOLFHOND: 'Perro lobo de Saarloos',
      SAINTUSUGE_SPANIEL: 'Saint-Usuge Spaniel',
      SAINT_BERNARD: 'San Bernardo',
      SALUKI: 'Saluki',
      SAMOYED: 'Samoyedo',
      SANSHU: 'Sanshu Inu',
      SCHAPENDOES: 'Schapendoes',
      SCHILLERSTOVARE: 'Sabueso de Schiller',
      SCHIPPERKE: 'Schipperke',
      SCHWEIZER_LAUFHUND: 'Sabueso de Schwyz',
      SCOTTISH_DEERHOUND: 'Galgo escocés',
      SCOTTISH_TERRIER: 'Terrier escocés',
      SEALYHAM_TERRIER: 'Sealyham Terrier',
      SHAR_PEI: 'Shar Peï',
      SHETLAND_SHEEPDOG: 'Pastor de las islas Shetland',
      SHIBA_INU: 'Shiba Inu',
      SHIH_TZU: 'Shih Tzu',
      SHIKOKU: 'Shikoku Inu',
      SHORTHAIRED_HUNGARIAN_POINTER: 'Braco húngaro de pelo corto',
      SIBERIAN_HUSKY: 'Husky siberiano',
      SILKY_TERRIER: 'Silky Terrier',
      SKYE_TERRIER: 'Skye Terrier',
      SLOUGHI: 'Sloughi',
      SLOVAKIAN_WIREHAIRED_POINTER: 'Braco eslovaco de pelo duro',
      SLOVAK_CUVAC: 'Pastor eslovaco',
      SLOVAK_HOUND: 'Sabueso eslovaco',
      SMALAND_HOUND: 'Sabueso de Smaland',
      SMALL_MUNSTERLANDER: 'Münsterländer pequeño',
      SMALL_SPANISH_HOUND: 'Sabueso español (pequeño)',
      SMOOTH_COLLIE: 'Collie de pelo corto',
      SMOOTH_FOX_TERRIER_ENGLISH_FOX_TERRIER: 'Fox terrier de pelo liso',
      SOFTCOATED_WHEATEN_TERRIER: 'Terrier irlandés de pelo suave',
      SOUTH_RUSSIAN_OVCHARKA: 'Pastor de Rusia meridional',
      SPANISH_GREYHOUND_GALGO_ESPANOL: 'Galgo español',
      SPANISH_MASTIFF: 'Mastín español',
      SPANISH_WATER_DOG: 'Perro de agua español',
      SPINONE_ITALIANO: 'Spinone Italiano',
      STABYHOUN: 'Perdiguero frisón',
      STAFFORDSHIRE_BULL_TERRIER: 'StaffordShire Bull Terrier',
      STANDARD_POODLE: 'Caniche (grande)',
      STANDARD_SCHNAUZER: 'Schnauzer mediano',
      STICHELHAAR: 'Perro de muestra alemán de pelo cerdoso',
      STYRIAN_COARSEHAIRED_HOUND: 'Sabueso estirio de pelo grueso',
      ST_GERMAIN_POINTING_DOG: 'Braco de Saint Germain',
      SUSSEX_SPANIEL: 'Sussex Spaniel',
      SWEDISH_ELKHOUND: 'Cazador de alces sueco',
      SWEDISH_LAPPHUND: 'Perro sueco de Laponia',
      TATRA_MOUNTAIN_SHEEPDOG: 'Pastor polaco de Podhale',
      TECKEL_DE_CHASSE_AU_LAPIN_POIL_DUR: 'Sabueso de Transilvania',
      TIBETAN_MASTIFF: 'Dogo del Tíbet',
      TIBETAN_SPANIEL: 'Spaniel tibetano',
      TIBETAN_TERRIER: 'Terrier tibetano',
      TOSA: 'Tosa Inu',
      TOY_POODLE: 'Caniche toy (miniatura)',
      TRICOLOUR_HOUND: 'Sabueso francés tricolor',
      TYROLEAN_HOUND: 'Sabueso del Tirol',
      WACHTELHUND: 'Perdiguero alemán',
      WEIMARANER_LONGHAIRED: 'Braco de Weimar de pelo largo',
      WEIMARANER_SHORTHAIRED: 'Braco de Weimar de pelo corto',
      WELSH_CORGI_CARDIGAN: 'Corgi galés de Cardigan',
      WELSH_CORGI_PEMBROKE: 'Corgi galés de Pembroke',
      WELSH_SPRINGER_SPANIEL: 'Springer Spaniel galés',
      WELSH_TERRIER: 'Terrier galés',
      WESTPHALIAN_DACHSBRACKE: 'Tejonero de Westfalia',
      WEST_HIGHLAND_WHITE_TERRIER: 'Terrier blanco de las tierras altas del oeste',
      WEST_SIBERIAN_LAIKA: 'Laika de Siberia Occidental',
      WHIPPET: 'Whippet',
      WHITE_SWISS_SHEPHERD_DOG: 'Pastor blanco suizo',
      WIREHAIRED_HUNGARIAN_POINTER: 'Braco húngaro de pelo duro',
      WIREHAIRED_POINTING_GRIFFON: 'Grifón de pelo lanoso',
      WIRE_FOX_TERRIER: 'Fox terrier de pelo duro',
      YORKSHIRE_TERRIER: 'Yorkshire Terrier',
      YUGOSLAVIAN_MOUNTAIN_HOUND: 'Sabueso de montaña de Montenegro',
      YUGOSLAVIAN_SHEPHERD_DOG: 'Pastor yugoslavo',
      YUGOSLAVIAN_TRICOLOUR_HOUND: 'Sabueso serbio tricolor'
    }
  },
  changePaymentMean: {
    ADD_BANK_ACCOUNT: 'Añadir una cuenta a cargar',
    ADD_BANK_CARD: 'Pagar con tarjeta de crédito',
    BANK_ACCOUNT_ERROR: 'Se ha producido un error al modificar sus datos bancarios. Póngase en contacto con el Servicio de Atención al Cliente.',
    BANK_ACCOUNT_EXPLANATION: 'Tenga en cuenta que ninguno de sus datos bancarios son guardados por nuestros servidores.',
    BANK_ACCOUNT_SUCCESS: 'Datos bancarios actualizados',
    BANK_CARD_ERRORED: 'Problema durante el cambio de método de pago. Póngase en contacto con el <1>Servicio de Atención al Cliente</1>.',
    BANK_CARD_UPDATED: 'Tarjeta bancaria actualizada',
    CAPTATION: 'Para actualizar su información bancaria, autorizaremos un cargo a su tarjeta de {{ amount }}.',
    CAPTATION_EXPLANATION1: 'Este importe no se deducirá. La transacción se cancela de inmediato.',
    CAPTATION_EXPLANATION2: 'Este procedimiento nos permite cumplir con las normas de pago de Internet, además de garantizar la seguridad de su información bancaria. No se divulgará esta información a través de nuestros servidores.',
    CAPTATION_EXPLANATION3: 'IMPORTANTE: el formulario de domiciliación llevará el nombre "Hareau" y no "Weenect". Es normal, se trata del nombre de nuestra empresa. "Weenect" es el nombre comercial.',
    CAPTATION_EXPLANATION4: 'Cambie la cuenta bancaria en la que se efectúa el cobro, conservando la misma suscripción.',
    CARD_AUTHORIZED: 'Medios de pago autorizados:',
    CHANGE_PAYMENT_MEAN: 'Actualizar mi tarjeta bancaria',
    CURRENT_CHOICE_CB: 'Eligió pagar con tarjeta de crédito.',
    CURRENT_CHOICE_IBAN: 'Usted ha elegido pagar por IBAN',
    CURRENT_MEAN: 'Forma de pago actual',
    CURRENT_MEAN_PHRASE: 'Ha elegido pagar con tarjeta de crédito',
    CURRENT_MEAN_PHRASE_GOCARDLESS: 'Ha elegido pagar por domiciliación bancaria',
    DEFAULT_MEAN: 'Método de pago predeterminado:',
    GO_TO_SEPA: 'Pasar al débito directo',
    MODIFY: 'Modificar mi método de pago',
    TITLE: 'Método de pago',
    UPDATE_BANK_ACCOUNT: 'Cambiar la cuenta en la que se efectuará el cargo',
    UPDATE_BANK_CARD: 'Actualizar mi tarjeta bancaria',
    USED_PAYMENT_EXPLANATION: 'Este método de pago se utiliza para pagar sus suscripciones y opciones.'
  },
  commonActions: {
    ACTIVATE: 'Activar',
    BACK: 'Volver',
    CANCEL: 'Anular',
    CAN_BE_CANCELED: 'Se puede cancelar en cualquier momento',
    CHANGE: 'Guardar',
    CONFIGURE: 'Configurar',
    DEACTIVATE: '',
    GO_MAP: 'Ir al mapa',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema'
    },
    NEXT: 'Siguiente',
    NO: 'No',
    OK: 'Aceptar',
    REGISTER: 'Activar',
    SAVE: 'Guardar',
    SELECT_ALL: 'Seleccionar todo',
    SEND: 'Enviar',
    SUBSCRIBE: 'Suscribirme',
    THEN: 'luego',
    UNSELECT_ALL: 'Deseleccionar todo',
    UPDATE: 'Guardar',
    VALIDATE: 'Validar',
    YES: 'Sí'
  },
  creditCardAlert: {
    AUTO_RENEWAL_ADVICE: 'Si quieres seguir utilizando tu localizador, añade una nueva forma de pago hoy mismo.',
    AUTO_RENEWAL_NOTICE: '<1>¡Atención!</1> Tu abono se acabará pronto y tu tarjeta bancaria ha expirado.'
  },
  emailPreferences: {
    EMAIL_LISTS: {
      COMPANY_NEWS: 'Novedades de la empresa',
      NEW_FEATURES: 'Nuevas funcionalidades',
      OFFERS: 'Ofertas promocionales',
      SERVICE_STATUS: 'Averías y actualizaciones',
      SURVEYS_AND_TESTS: 'Encuestas y tests Beta',
      TUTORIALS: 'Tutoriales'
    },
    EMAIL_LISTS_HEADER: 'Lista de correo electrónico',
    EMAIL_LISTS_INFO: {
      SERVICE_STATUS: 'Necesario para el correcto funcionamiento del servicio.'
    },
    LANGUAGE_PREFERENCES_DESCRIPTION: 'Elija el idioma en el que desea recibir los correos electrónicos :',
    PREFERENCES_DECRIPTION: 'Elija el tipo de correos electrónicos que desea recibir. Sólo le enviaremos los que coincidan con sus elecciones. '
  },
  error: {
    TITLE: 'Ha surgido un problema '
  },
  errors: {
    DEFAULT: 'Campo no válido',
    FIELD_REQUIRED: 'Este campo es obligatorio',
    GENERAL: 'Ha ocurrido un error, inténtelo de nuevo',
    IMEI: 'Introduzca un número IMEI válido',
    MAIL_FORMAT: 'Este formato de correo electrónico no es válido',
    MAX_LENGTH: 'El campo puede contener 15 caracteres como máximo',
    PASSWORD_FORMAT: 'Su contraseña debe contener al menos un número, una mayúscula, y una minúscula.',
    PHONE_FORMAT: 'El número de teléfono debe incluir el prefijo internacional',
    PROMO_CODE: 'Código promocional no válido'
  },
  forgotPassword: {
    FORGOT_PASSWORD_BUTTON: 'Recibir un enlace de contraseña olvidada',
    FORGOT_PASSWORD_CANCEL: 'Volver a conectarse',
    FORGOT_PASSWORD_ERRORS: {
      UNKNOWN: 'Se ha producido un error. Póngase en contacto con nosotros a través de nuestro formulario.'
    },
    FORGOT_PASSWORD_EXPIRED: 'El enlace para reiniciar la contraseña ha caducado.',
    FORGOT_PASSWORD_RETRY: 'Solicitar un nuevo enlace de contraseña olvidada',
    FORGOT_PASSWORD_SUCCESS: 'Si existe una cuenta asociada a la dirección {{mail}}, recibirás un correo electrónico con un enlace para reinicializar tu contraseña.'
  },
  forms: {
    CITY: {
      LABEL: 'Ciudad',
      PLACEHOLDER: 'Tu ciutad'
    },
    CONFIRM_PASSWORD: {
      LABEL: '¿Olvidaste tu contraseña?',
      PLACEHOLDER: 'Confirmar'
    },
    COUNTRY: {
      LABEL: 'País'
    },
    EMAIL: {
      LABEL: 'Correo electrónico',
      PLACEHOLDER: 'votre@email.com'
    },
    FIRST_NAME: {
      LABEL: 'Nombre',
      PLACEHOLDER: 'Su nombre'
    },
    LANGUAGE: {
      LABEL: 'Idioma'
    },
    LAST_NAME: {
      LABEL: 'Apellidos',
      PLACEHOLDER: 'Sus apellidos'
    },
    NEW_PASSWORD: {
      LABEL: 'Nueva contraseña',
      PLACEHOLDER: 'Su nueva contraseña'
    },
    OLD_PASSWORD: {
      LABEL: 'Contraseña anterior',
      PLACEHOLDER: 'Su contraseña anterior'
    },
    PASSWORD: {
      LABEL: 'Contraseña',
      PLACEHOLDER: 'Contraseña',
      VALIDATION: 'Su contraseña debería tener al menos 8 caracteres',
      WARNING: 'Cuidado, "qwertyuiop" y "12345678" NO estan contraseñas seguras.\nPrueba la securitad de tu contraseña aquí : <1>https://howsecureismypassword.net</1>'
    },
    PHONE: {
      LABEL: 'Teléfono',
      PLACEHOLDER: '+33600000000'
    },
    POSTAL_CODE: {
      LABEL: 'Código postal',
      PLACEHOLDER: 'Su código postal',
      STREET_NUMBER: 'Calle y número'
    },
    STREET: {
      LABEL: 'Dirección',
      PLACEHOLDER: 'Nombre y número de la calle'
    }
  },
  geofences: {
    CREATE: {
      HEADLINE: 'Crear una zona'
    },
    EDIT: {
      DELETE_GEOFENCE: 'Eliminar la zona',
      ENTRANCE_NOTIFICATION: 'Notificación de entrada a la zona',
      EXIT_NOTIFICATION: 'Notificación de salida de la zona',
      GEOCODE_LABEL: 'Utilizar una dirección',
      GEOCODE_PLACEHOLDER: 'Escriba la dirección aquí',
      LOCATION: 'Posición del centro de la zona',
      NAME_INPUT: {
        LABEL: 'Nombre de la zona',
        PLACRHOLDER: 'Escriba el nombre de la zona'
      },
      OUTDOORS: 'Zona exterior (parque, bosque)',
      OUTDOORS_TOOLTIP: 'Dado que el GPS es más preciso en exteriores, las reglas para activar una notificación de entrada o salida son diferentes en una zona interior y una exterior.',
      POINT_ON_THE_MAP_TEXT: 'o hacer clic directamente en el mapa',
      RADIUS: 'Tamaño de zona (radio)',
      TRACKER_LAST_POSITION_BUTTON: 'Utilizar la posición del localizador',
      UNKNOWN_ADDRESS: 'Dirección desconocida'
    }
  },
  header: {
    ALL_TRACKERS: 'Todos los localizadores',
    AVAILABLE_PLURAL: 'disponible(s)',
    NAV: {
      ACCOUNT: 'Cuenta',
      INSURANCE: 'Seguro',
      LOGOUT: 'Cerrar sesión',
      SUPPORT: 'Asistencia',
      TRACKERS: 'Mapa'
    },
    SEARCHBAR_RESULT: 'localizadores encontrados',
    SEARCHBAR_TITLE: 'Buscar un localizador:',
    TRACKERS: 'Localizador(es)',
    WHITE_LABEL: {
      IN_PARTERNSHIP_WITH: 'En asociación con'
    }
  },
  imeiInfo: {
    HINT: 'Encontrará el número IMEI en la parte trasera del localizador.',
    IMAGE_ALT: 'Foto del localizador',
    TITLE: 'Número IMEI'
  },
  login: {
    FORGOT_PASSWORD: '¿Contraseña olvidada?',
    LOGIN_ERRORS: {
      UNKNOWN: 'Ha fallado la conexión'
    },
    SIGN_IN_BUTTON: 'Conectarse',
    SIGN_IN_HEADLINE: 'Conectarse',
    SIGN_UP_BUTTON: 'Crear una cuenta',
    SIGN_UP_HEADLINE: '¿Aún no tiene cuenta?',
    TERMS_LINK: 'Condiciones de uso',
    TERMS_LINK_URL: 'https://www.weenect.com/common/pdf/en/cgv-weenect.pdf'
  },
  manageSubscription: {
    CANCEL: {
      DESCRIBE_YOUR_PROBLEMS: '¿Podría especificar el motivo de la cancelación de su suscripción?',
      GIVE_US_DETAILS: 'Motivo de la cancelación de su suscripción.',
      REASONS: {
        AFTER_SALES_SERVICE: 'Mala experiencia con el Servicio de Atención al Cliente',
        BATTERY: 'Autonomía de la batería insuficiente',
        LOST_BROKEN: 'La he perdido/roto.',
        MOBILE_APPLICATION: 'La aplicación no funciona',
        NOT_SATISFIED: 'No estoy conforme con Weenect.',
        NO_USE: 'Ya no la necesito.',
        TOO_EXPENSIVE: 'La suscripción es demasiado cara.',
        TRACKER_FORMAT: 'Formato del localizador por revisar (tamaño y peso)',
        WILL_REACTIVATE_LATER: 'Reactivaré la suscripción más tarde'
      },
      SAD_TO_SEE_YOU_LEAVE: 'Lamentamos que se vaya.',
      WHY_DO_YOU_RESIGN: '¿Por qué motivo quiere cancelar el plan?'
    },
    CANCEL_EXPIRE: 'Se ha cancelado la renovación automática. La suscripción caducará el {{date}}.',
    CANCEL_QUESTION: '¿No desea continuar con su suscripción?',
    CANCEL_RENEWAL: 'Cancelar la renovación automática',
    CANCEL_RENEWAL_BIS: 'Cancelar la suscripción',
    CANT_RESIGN_UNDER_EXTENDED_NOTICE: '¿Quiere cancelar su suscripción o simplemente desactivar la renovación automática de su suscripción?\nLlámenos al  +33 (0)1 87 66 98 71 de 09:30 a 16 h de lunes a viernes.',
    CANT_RESIGN_UNDER_NOTICE: 'No es posible cancelar su suscripción durante los últimos treinta días. Por favor contacte con el equipe de atención al cliente.',
    CANT_RESIGN_UNTIL: 'No puede cancelar su suscripción antes del {{date}}',
    CANT_RESIGN_WHILE_COMMITED: 'Estas comprometido por 3 meses, la terminación de su suscripción sólo tendrá efecto al final del período actual, es decir, el {{date}}',
    CURRENT_PLAN: 'Abono actual',
    HEADLINE: 'Gestionar suscripción',
    MESSAGES: {
      UPDATE_SUCCESS: 'Suscripción actualizada correctamente.'
    },
    RENEW_SUBSCRIPTION: 'Renovar suscripción',
    RESIGN_DATE: 'La terminación de su suscripción tendrá efecto al final del período actual, que es {{date}}',
    RESUMPTION_DATE: 'Elija una fecha de reanudación',
    RETURN_TO_TRACKERS: 'Volver al mapa'
  },
  map: {
    NO_GPS_MARKER_TITLE: 'No hay señal GPS',
    POSITION: 'Posición'
  },
  mapControls: {
    MAP_TYPE: 'Tipo de mapa',
    REFRESH_POSITION: 'Actualizar la posición',
    SUPER_LIVE: 'Super en vivo',
    ZOOM_IN: 'Zoom +',
    ZOOM_OUT: 'Zoom -'
  },
  mapLayers: {
    CLASSIC_MAPS: 'Mapas estándares',
    DONT_SUBSCRIBE: 'Seguir con los mapas clásicos',
    FOREST_PATH: 'Senderos y caminos del bosque',
    GOOGLE_STREET: 'Google Maps',
    GRAY: 'Blanco y negro',
    PLAN: 'Plan',
    PREMIUM_MAPS: 'Mapas Premium',
    PREMIUM_SUBSCRIBE: 'Suscribirse a la opción mapas premium',
    PREMIUM_WARNING_MSG: 'Ups, aún no estás suscrito a la opción mapas Premium !\n\nEsta opción te permitirá aprovechar unos mapas más completos y actualizados de forma regular, para observar mejor los desplazamientos de tu localizador. \u2028Las versiones satélite HD, los mapas con relieve, o los caminos por el bosque te permitirán encontrar tu localizador fácilmente.\n\n¡Suscríbete a esta opción por solo {{mapOptionPrice}}/mes!',
    RELIEF: 'Relieves',
    SATELLITE: 'Satélite',
    STREET: 'Clásico',
    UNLOCK_MAPS: 'Desbloquea otras cartas haciendo clic en ellas.'
  },
  misc: {
    LANGUAGE: {
      DE: 'Alemán',
      EN: 'Inglés',
      ES: 'Español',
      FR: 'Francés',
      IT: 'Italiano',
      NL: 'Holandés'
    },
    NO_DATE: 'Ninguna posición conocida',
    ROTATE_DEVICE: 'Weenect se ha diseñado para usarse en modo vertical. Gire el dispositivo para aprovechar al máximo nuestros servicios.',
    locale: 'es-ES'
  },
  onboarding: {
    ACTIVATING_HINT: 'Duración media de activación de 5 minutos',
    ACTIVATING_INDICATOR: 'En proceso de activación',
    GREET: {
      BUTTON: 'Mi localizador está encendido',
      COMMON_TEXT: 'Para empezar bien, le recomendamos seguir este tutorial. Le ayudará en el manejo de su localizador.\n\nEmpecemos con la puesta en marcha de su localizador. Siga estas indicaciones para encenderlo:',
      MAIN_TITLE: 'Información de contacto',
      RED_TEXT: 'Es necesario que el localizador esté en el exterior para que pueda obtener coordenadas GPS'
    },
    MAP_INTERACTIONS: {
      COMMON_TEXT: {
        PET: 'De arriba abajo, estos botones le permiten hacer sonar o vibrar su localizador. Estas funciones le permiten enseñar a su mascota a acudir a la llamada.',
        SILVER: 'De arriba abajo, estos botones le permiten hacer sonar o vibrar su localizador. '
      },
      TITLE: 'Los botones de interacción con el localizador'
    },
    MAP_INTERVAL: {
      COMMON_TEXT: 'Este botón le permite elegir con qué frecuencia quiere recibir nuevas coordenadas GPS para su localizador. Atención, cuanto más corto es el intervalo, menos elevada será la autonomía del localizador.',
      TITLE: 'El intervalo de tiempo del informe del GPS'
    },
    MAP_OPTIONS: {
      COMMON_TEXT: 'Estos botones le permiten modificar el tipo de mapa que desea usar, y también le dejan acercar y alejar el zoom.',
      TITLE: 'Los botones de ajuste del mapa'
    },
    MAP_UPDATE: {
      COMMON_TEXT: 'Este botón le permite actualizar la posición de su localizador (para obtener una nueva posición, su localizador debe estar conectado al servidor y tener una señal GPS válida).',
      TITLE: 'El botón de actualización de la posición'
    },
    MODAL: {
      BUTTON_HINT: 'Pulse el botón durante 3 segundos hasta que el localizador vibre.',
      HEADLINE: 'Encienda el localizador al aire libre o en el alféizar de una ventana',
      IMAGE_ALT: 'Foto del localizador',
      MAP_HINT: 'Una vez encendido, el localizador se mostrará en el mapa en un plazo de 5 minutos de media.'
    },
    REMOTE_ASSISTANCE: {
      ASSISTANCE: {
        COMMON_TEXT: 'Si nadie reacciona en este periodo de tiempo, la alerta se transmite a un operador'
      },
      EXPLANATION: 'El operador se hace cargo de la llamada y responde a la necesidad del portador del localizador. Dependiendo de la situación, puede <1>ponerse en contacto con parientes o pedir ayuda.</1>.',
      PRICE: 'La opción cuesta 9,99 € al mes y lo puede desactivar en cualquier momento.',
      SOS: {
        COMMON_TEXT: 'Su pariente pulsa el botón de SOS de su localizador.'
      },
      TIME: {
        COMMON_TEXT: 'Se le avisa y tiene 30 segundos para responder a la alerta.'
      },
      TITLE: 'Descubra nuestra nueva opción: la teleasistencia ',
      USE_CASE: 'Esta opción permite poner al portador del localizador en contacto con un departamento especializado de teleasistencia disponible las 24 horas al día, los 7 días de la semana.'
    },
    SIDE_PAGES: {
      COMMON_TEXT: {
        PET: 'En el panel izquierdo, puede encontrar todas las páginas de gestión y uso de su localizador. La pestaña {{ Historial }} le permite consultar los desplazamientos de su mascota y la pestaña {{ Control de actividades }}, conocer su consumo de energía. La pestaña {{ Zonas }} le permite configurarlas, la pestaña {{ Parámetros }} contiene el perfil de su mascota y la pestaña {{ Suscripción }} le permite gestionar su suscripción, así como sus opciones muy fácilmente.',
        SILVER: 'En el panel izquierdo, puede encontrar todas las páginas de gestión y uso de su localizador. La pestaña < Historial > le permite consultar los desplazamientos de su familiar y la pestaña < Zonas > le permite configurar zonas de seguridad.\n La pestaña < Parámetros > le permite configurar el número al que llamar en caso de emergencia, así como los mensajes asociados a cada botón lateral del localizador. Por último, la pestaña <Suscripción> le permite gestionar su suscripción, así como sus opciones muy fácilmente.'
      },
      TITLE: 'Páginas adicionales'
    },
    SKIP: 'Saltar el tutorial',
    SUPERLIVE: {
      COMMON_TEXT: 'Este botón le permite activar el modo "superlive": una nueva posición cada 10 segundos durante 5 minutos.',
      TITLE: 'El botón "superlive"'
    },
    TUTORIAL_START: {
      BUTTON: 'Continuar con el tutorial',
      COMMON_TEXT: 'Perfecto, su localizador ya está listo para su uso. Sigamos con una breve introducción de su espacio personal My Weenect.'
    },
    WAITING_GPS_POSITION: 'Búsqueda de una posición GPS',
    WAITING_GPS_POSITION_HINT: 'Encienda su localizador en el exterior'
  },
  optionCardExpired: {
    PROPOSAL: 'Actualice su tarjeta bancaria para seguir disfrutando de nuestros servicios.',
    UPDATE_CARD: 'Actualizar ahora',
    WARNING: '<1>¡Atención!</1>No puede suscribirse a esta opción, puesto que <1>su tarjeta bancaria ha caducado</1>.'
  },
  payment: {
    ADD_NEW_PAYMENT_REGULARISE: 'Registre un nuevo método de pago o regularice su situación.',
    EXPIRACY_CREDIT_CARD_TEXT: 'Añada un nuevo método de pago para regularizar su situación.',
    NO_PAYMENT_MEAN_YET: 'Ningún medio de pago por el momento',
    NO_PAYMENT_YET: 'Ningún medio de pago en este momento',
    UPDATE_PAYMENT_REGULARISE: 'Actualízala hoy mismo o cámbiala si quieres seguir utilizando tu localizador.',
    WARNING_ERROR: 'Parece que hay un problema con tu forma de pago.',
    WARNING_EXPIRACY_CREDIT_CARD: 'Cuidado, su tarjeta de crédito ha caducado.',
    WARNING_NOT_ENOUGH_FOUNDS: 'Atención, parece que no hay fondos suficientes en su cuenta.'
  },
  payments: {
    CARD: {
      ACCEPTED: '(CB, VISA, Mastercard, American Express)',
      DESCRIPTION: 'Plazo de devolución de 30 días',
      NAME: 'Tarjeta de crédito'
    },
    STATUS: {
      CANCELED: 'Cancelado',
      ERROR: 'Fallido',
      NEW: 'Procesándose',
      PAYMENT_ERROR: 'Error en el pago',
      PAYMENT_ERROR_EXPLAIN_TEXT: 'Ha ocurrido un error en el pago. Vuelve a probar en unos instantes o ponte en contacto',
      PAYMENT_RETRY: 'Volver a intentar',
      PAYMENT_SAV: 'el servicio de atención al cliente.',
      PAYMENT_SUCCESS: '¡Pago terminado!',
      PAYMENT_SUCCESS_TEXT: 'Se ha registrado correctamente el pago. Ya puedes disfrutar de tu experiencia en Weenect.',
      PAYMENT_SUCCESS_TEXT_APP: 'Se ha registrado correctamente el pago.',
      PENDING: 'Procesándose',
      REFUNDED: 'Reembolsado',
      SUCCESS: 'Confirmado'
    },
    TRANSFER: {
      DESCRIPTION: '15 días adicionales para cambiar de opinión, es decir, 30 días con opción de reembolso',
      NAME: 'Adeudo bancario'
    }
  },
  periods: {
    ALT: {
      MONTH: '1 mes',
      YEAR: '1 año',
      YEAR_plural: '{{count}} años'
    },
    BASE: {
      MONTH: 'mes(es)',
      YEAR: 'año'
    }
  },
  registerSummaryTable: {
    BILLED_MONTH: 'El pago es mensual',
    BILLED_NOW: 'Facturado inmediatamente',
    BILLED_THREE_YEARS: 'Il pagamento viene effettuato una volta ogni tre anni',
    BILLED_TWO_YEARS: 'Facturada una vez cada dos años',
    BILLED_YEAR: 'El pago se efectúa una vez al año',
    HINT_OPTIONS_PRICE: 'Las opciones, por un importe de {{amount}}, se facturarán aparte de la suscripción dentro de dos horas.',
    OPTION_UPGRADE: 'Añadir la opción {{name}} por solo {{price}}/{{period}}',
    PAY_HINT: 'Haga clic en el botón "Pagar y activar" y pague {{subscriptionPrice}} por la suscripción.',
    SUBSCRIPTION_UPGRADE: 'Cambie a una suscripción de 2 años y ahorre {{amount}} al año.',
    TOTAL: 'Precio total'
  },
  registerTracker: {
    BUY_LINK: '¿Aún no tiene un localizador Weenect? <1>Puede adquirirlo aquí</1>',
    ERRORS: {
      UNKNOWN: 'Ha habido un problema al activar el localizador, inténtelo de nuevo'
    },
    HEADLINE: 'Activar un nuevo localizador',
    IMEI_INPUT: {
      LABEL: 'Número IMEI',
      PLACEHOLDER: 'Encontrará el número IMEI en la parte trasera del localizador.'
    },
    NAME_INPUT: {
      LABEL: 'Nombre del localizador',
      PLACEHOLDER: 'Nombre del localizador'
    }
  },
  remoteAssistance: {
    BASE: 'Teleasistencia',
    FEATURES: 'Tras pulsarse el botón SOS, si no responde en 30 segundos, la alerta SOS y la llamada de voz se transmiten automáticamente, las 24 horas, 7 días a la semana, a nuestra central de teleasistencia. Nuestros agentes están autorizados a ponerse en contacto con servicios de emergencia como la policía o el 112.',
    FORM: {
      ADDRESS_INPUT: {
        LABEL: 'Dirección',
        PLACEHOLDER: 'Nombre y número de la vía'
      },
      BACK: 'Cancelar',
      CITY_INPUT: {
        LABEL: 'Ciudad',
        PLACEHOLDER: ''
      },
      EXTRA_PHONE: {
        LABEL: 'Teléfono secundario (opcional)',
        PLACEHOLDER: ''
      },
      HEADLINE: 'Registro en la teleasistencia',
      NAME_INPUT: {
        LABEL: 'Nombre completo',
        PLACEHOLDER: 'Nombre APELLIDOS'
      },
      PHONE: {
        LABEL: 'Teléfono',
        PLACEHOLDER: ''
      },
      POSTAL_CODE_INPUT: {
        LABEL: 'Código postal',
        PLACEHOLDER: ''
      },
      SAVE: 'Confirmar',
      TERMS: 'He leído y acepto <1>las condiciones de uso</1> del servicio',
      TEXT: 'Introduzca los datos del usuario del localizador. Nuestros agentes podrán atenderle en caso de emitirse una alerta SOS.'
    },
    PENDING: {
      WAITING_FOR_CALL_TEST: 'Formulario de inscripción validado. Pendiente de la llamada de prueba.\nAhora debe efectuar una llamada de prueba para finalizar la activación de la opción. Consulte el correo electrónico que ha recibido para ver el procedimiento que debe seguir.',
      WAITING_FOR_RESIGN: 'Desactivación de la opción en curso.\nSe ha recibido su solicitud para desactivar la opción. Nuestros agentes se encargan actualmente de las formalidades administrativas. La desactivación se efectuará en breve.',
      WAITING_FOR_VALIDATION: 'Formulario de inscripción en curso de validación. \nNuestros agentes están verificando la información que proporcionó al suscribirse a la opción. Una vez validada esta información, procederemos al último paso para activar la opción: deberá efectuarse una llamada de prueba para comprobar que todo funciona correctamente.'
    },
    USER_PHONE: {
      TEXT: 'Introduzca sus datos. Nuestros agentes podrán contactar con usted si se activa una alerta SOS.'
    }
  },
  santevet: {
    ADVANTAGE: {
      FIRST: '<span>6 meses de suscripción a Weenect ofrecidos</span> en la suscripción',
      PROMO_CODE: 'con el código',
      SECOND: '<span>20€ de prevención ofrecidos/año</span> además del paquete ya incluido'
    },
    FOOTER: {
      BUTTON: 'Ver precios',
      TEXT: 'Al continuar, aceptas ser contactado por Santévet.'
    },
    OFFERS: {
      BILL: {
        TEXT: 'Del <span>60% al 100%</span> y hasta 2.500€/año.',
        TITLE: 'Tus costos reembolsados en caso de enfermedades y accidentes'
      },
      CROSS: {
        TEXT: 'Benefíciate <span>gratuitamente</span> y <span>en cualquier momento</span> de una asistencia telefónica.',
        TITLE: 'Un servicio de emergencia veterinaria 24/7'
      },
      MONEY_PUSH: {
        TEXT: 'Santévet adelanta tus costos <span>gratuitamente</span> al final de la consulta.',
        TITLE: 'El adelanto de tus gastos veterinarios con Payvet'
      },
      SERINGE: {
        TEXT: '<span>Hasta 150€/año</span> para cuidados preventivos (vacunas, antiparasitarios, etc).',
        TITLE: 'Un paquete de prevención incluido en todas las ofertas'
      },
      TITLE: 'Las ventajas de Santévet:'
    },
    SUBTITLE: 'y disfrute de ventajas exclusivas',
    TITLE: 'Asegura a {{name}} por solo {{amount}} €/mes',
    TITLE_WITHOUT_PRICE: 'Asegura {{name}} de inmediato'
  },
  signup: {
    ALREADY_MEMBER_TEXT: '¿Ya eres usuario?',
    CREATE_ACCOUNT_HEADLINE: 'Crear una cuenta',
    LOGIN_TEXT: 'Conectarse',
    MARKETING_AGREEMENT_TEXT: 'Acepto recibir ofertas y promociones de los socios de Weenect',
    RETRY: 'Intentar de nuevo',
    SIGNUP_ERRORS: {
      EMAIL_ALREADY_EXISTS: 'Este correo electrónico ya está asociado a una cuenta',
      POPIN_EMAIL_ALREADY_EXIST: '<1>Ups</1>, parece que este correo ya tiene una cuenta',
      UNKNOWN: 'Ha fallado la conexión, reintentar'
    },
    SIGN_IN_TEXT: 'Conectarse',
    SUBMIT_BUTTON: 'Crear mi cuenta',
    TERMS_AGREEMENT_TEXT: 'Acepto <1>las condiciones de uso</1> del servicio'
  },
  simInfo: {
    TITLE: 'Número SIM'
  },
  subOptions: {
    CANCEL: {
      EXPIRATION_DATE: 'Esta opción seguirá estando activa hasta el {{date}}',
      HEADLINE: '¿Desea cancelar la opción {{option}}?'
    },
    MAP: {
      BASE: 'Pack multi-mapas',
      FEATURES: 'Aprovecha unos mapas más completos y actualizados de forma regular, para observar mejor los desplazamientos de tu localizador. (Satélite HD, mapa con relieve, caminos por el bosque).',
      TITLE: 'Mapas en alta definición: '
    },
    PREMIUM: {
      BASE: 'Paquete Premium',
      BUTTON: {
        FREEMONTH: 'Prueba gratuita durante 1 mes.'
      },
      DEACTIVATE: 'Al desactivar esta opción, perderá los beneficios de la prima.',
      FEATURE: {
        CHAT: {
          BASE: 'Chat en línea',
          TEXT: 'Habla en directo con un asesor de Weenect desde tu aplicación. Servicio disponible de 9:00 a 18:00 los 7 días de la semana.'
        },
        SMS: {
          TEXT: 'Recibe todas las alertas por mensaje de texto (crédito de 40 mensajes al mes), además de las notificaciones push y los correos electrónicos ya incluidos en el abono.'
        }
      },
      FEATURE_TEXT: 'Disfrute de ventajas exclusivas (alertas por SMS, mapas en alta definición) dentro de la aplicación.',
      FREEMONTH: {
        BASE: 'Primer mes gratis',
        BASE2: 'GRATIS'
      },
      SUBTITLE: 'El Pack Premium incluye las siguientes características:',
      SUB_LEGAL: 'Esta afiliación se renovará automáticamente cada mes, a través de la forma de pago elegida al suscribirte. Puedes cancelar la afiliación en cualquier momento a través de tu espacio personal.',
      TEXT_INFO_PRICE: 'El servicio se cobra a {{price}}/mes después del primer mes.',
      TITLE_DISCOVER: 'Descubre las ventajas exclusivas:',
      TITLE_POPUP: '¡Hazte miembro Premium!'
    },
    SMS_PACK: {
      ALT_FEATURES: {
        one: 'Reciba todas las alertas por SMS',
        other: ''
      },
      BASE: 'Pack SMS',
      FEATURES: {
        one: 'Recibe todas las alertas por SMS (20 SMS disponibles al mes). Ten en cuenta que sin esta opción recibirás las alertas mediante pushs (aplicación) y correos electrónicos.',
        other: ''
      }
    },
    UNDEFINED: {
      PAYMENTS: 'Pago para la opción'
    },
    WARRANTY: {
      ALT_FEATURES: {
        one: 'Consiga gratuitamente un nuevo localizador en caso de rotura, pérdida o robo de su localizador actual.',
        other: 'Pago con tarjeta de débito con posibilidad de cancelación en cualquier momento',
        two: 'Su plotter reemplazado en 48H en caso de pérdida, rotura o robo, sin condiciones.'
      },
      BASE: 'Garantía multirriesgo',
      EXPLAINER: 'La garantía estándar solo cubre los problemas de fabricación del localizador.',
      FEATURES: 'Consigue un localizador de sustitución gratuito en caso de que tu localizador actual se rompa, pierda o haya sido robado; no se pide justificación. La garantía estándar solo cubre los problemas de fabricación del localizador.'
    }
  },
  subscription: {
    CANCEL_PAGE: {
      BUTTON: '',
      TEXT: '',
      TITLE: ''
    },
    COMMON_TEXT: {
      MONTH: '/ mes',
      OR: 'Osea'
    },
    FAIL_PAGE: {
      BUTTON: 'Intentar de nuevo',
      TEXT: 'Su pago no se ha completado. Le invitamos a intentarlo de nuevo.',
      TITLE: 'Pago fallido'
    },
    FEATURES: {
      APP_ACCESS: 'Acceso ilimitado a la aplicación y al servicio de localización',
      REFOUND: '15 días de prueba: satisfecho o le devolvemos el dinero',
      SAV: 'Servicio de atención al cliente disponible 6/7 días. \nRespuesta garantizada en 24 horas.',
      WARRANTY: '24 meses de garantía del fabricante'
    },
    FIRST_PAGE: {
      BUTTON: 'Siguiente',
      BY_YEAR: 'Es decir, {{price}} al año',
      FOR_YEAR: 'Es decir, {{price}} por {{period}} años',
      MODAL_GIFT_CARD: {
        BUTTON: 'Aplicar',
        DELETE_BUTTON: 'Remove',
        ERROR: '',
        INPUT_LABEL: 'Tarjeta de regalo',
        TITLE: 'Agregar una tarjeta de regalo'
      },
      MODAL_INFORMATION: {
        TEXT: 'La tarjeta SIM integrada directamente en tu localizador requiere la suscripción a un servicio (para cubrir los costos del servicio).',
        TITLE: '¿Por qué elegir una suscripción?'
      },
      SUBSCRIPTION_CGV: {
        FIRST_TEXT: 'Todas las suscripciones se facturan inmediatamente, pueden cancelarse dentro de los 15 días, con un reembolso total del importe pagado.',
        INCLUDES: {
          FIRST: 'satisfecho o reembolsado',
          FIRST_BOLD: '15 o 30 días de prueba mínima según la suscripción:',
          FOURTH: 'fabricante 24 meses mínimo',
          FOURTH_BOLD: 'Garantía',
          SECOND: 'y al servicio de localización ilimitado.',
          SECOND_BOLD: 'Acceso a la aplicación',
          THIRD: 'disponible 6/7 días. Respuesta garantizada en 24 horas.',
          THIRD_BOLD: 'Servicio al cliente'
        },
        SECOND_TEXT: 'Para beneficiarte de las suscripciones, debes ingresar información bancaria válida y aceptar los',
        SECOND_TEXT_LINK: 'condiciones generales de uso del servicio.',
        TITLE: 'Todas las suscripciones incluyen:'
      },
      SUBSCRIPTION_CHOICE: {
        CHIP_ONE_MONTH: 'Oferta flexible',
        CHIP_ONE_YEAR: 'Oferta popular',
        CHIP_THREE_YEAR: 'Ahorra {{count}}%',
        SUBSCRIPTION_TITLE_GIFT_CARD: 'Tengo una tarjeta de regalo',
        SUBSCRIPTION_TITLE_MONTHLY: 'Suscripción mensual',
        SUBSCRIPTION_TITLE_one: 'Suscripción de {{count}} año',
        SUBSCRIPTION_TITLE_other: 'Suscripción de {{count}} años',
        TITLE: 'Elige tu suscripción'
      }
    },
    FOR_MINUS_X_PERCENT: '',
    FREE: 'GRATIS',
    HEADER: {
      STEP: 'Paso',
      TITLE: {
        FIRST_PAGE: 'Suscripción',
        SECOND_PAGE: 'Opciones',
        THIRD_PAGE: 'Pago'
      }
    },
    INFO_CGU: 'Para beneficiarse de las suscripciones, debe introducir información bancaria válida y aceptar las condiciones generales',
    INFO_CGU_BUTTON: 'las condiciones generales de uso del servicio.',
    INFO_OPTION: 'El pago es mensual, la cancelación es posible en cualquier momento.',
    MONEY_BACK: 'Todas las suscripciones se facturan inmediatamente, pero pueden cancelarse en un plazo de 15 días, con el reembolso íntegro del importe pagado.',
    MONTH: 'Mes',
    PAYMENT_INFO: 'Garantía de devolución del dinero: 15 días para probar el localizador',
    PAYMENT_SUBHEADLINE: 'Seleccione un método de pago para pagar su suscripción y activar su localizador.',
    PAYMENT_TITLE: 'Elija su método de pago',
    PAYMENT_TITLE2: 'Registre su método de pago',
    PENDING_PAGE: {
      BUTTON: '',
      TEXT: '',
      TIMEOUT_TEXT: '',
      TITLE: ''
    },
    PRICE_MONTH_SAVED: 'osea {{price}} / mes',
    SECONDE_PAGE: {
      BUTTON: 'Ir al pago'
    },
    SECOND_PAGE: {
      BASKET: {
        TEXT: {
          premium_pack: 'Primer mes gratis, luego',
          warranty: 'El pago es mensual.'
        },
        TOTAL: 'Total con impuestos incluidos'
      },
      OPTION_CHOICE: {
        CHIP: '1er mes gratis',
        TEXT: {
          premium_pack: 'Desbloquea funciones exclusivas: Superlive hasta 30 minutos, vistas de mapas adicionales, otro estilo de linterna o incluso alertas por SMS.',
          warranty: 'Obtén un localizador de reemplazo gratuito en caso de rotura, pérdida o robo de tu localizador actual; no se requiere ninguna justificación.'
        },
        TITLE: {
          premium_pack: 'Paquete Premium',
          warranty: 'Garantía multirriesgo'
        }
      },
      SUBTITLE: 'Por defecto, las opciones están seleccionadas, pero puede deseleccionarlas haciendo clic sobre ellas.',
      TITLE: 'Elija sus opciones'
    },
    SUCCESS_PAGE: {
      BUTTON: 'Volver a la aplicación',
      TEXT: 'Su pago ha sido procesado con éxito. Ahora puede volver a la aplicación.',
      TITLE: 'Pago exitoso'
    },
    SUMMARY: 'Resumen',
    THIRD_PAGE: {
      BUTTON: 'Confirmar y pagar',
      MODAL: {
        ERROR: '',
        TITLE: 'Código promocional'
      },
      PAYMENT_CHOICE: {
        BANK: 'Domiciliación bancaria',
        CARD: 'Tarjeta de crédito',
        TITLE: 'Elija su método de pago'
      },
      SUBTITLE: 'Seleccione un método de pago para pagar su suscripción y activar su localizador.'
    },
    UNKNOWN_PAGE: {
      BUTTON: '',
      TEXT: '',
      TITLE: ''
    }
  },
  subscriptionAlert: {
    ACTIVATE_AUTO_RENEWAL: 'Volver a activar la renovación automática',
    AUTO_RENEWAL_ADVICE: 'Le aconsejamos que vuelva a activar la renovación automática si quiere prolongar su suscripción',
    AUTO_RENEWAL_NOTICE: '<1>¡Atención!</1> Su suscripción para el localizador {{trackerName}} va a terminar y la renovación automática está desactivada.',
    CONTINUE_BUTTON: 'Continuar',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema',
      UPDATE_SUCCESS: 'Actualizado correctamente'
    },
    REMIND_LATER: 'Recordármelo más tarde',
    SUPPORT_TEXT: 'Si tiene preguntas o cree que es un error, póngase en contacto con nuestro <1>Servicio de Atención al Cliente</1>.',
    TRIAL_CASE: {
      SUBSCRIBE_BUTTON: 'Contratar una suscripción',
      TRIAL_IS_OVER: 'Tu periodo de prueba ha terminado',
      TRIAL_IS_OVER_ADVICE: 'Esperamos que la prueba haya sido un éxito. Para seguir utilizando el producto, ahora necesitas contratar una suscripción.'
    }
  },
  subscriptionOptionsSelection: {
    CANCEL_WARNING_MODAL_HEADLINE: '¿Seguro que desea desactivar esta opción?',
    CANCEL_WARNING_SMS: 'Al desactivar esta opción, no recibirá las notificaciones de las alertas por SMS.',
    CANCEL_WARNING_WARRANTY: 'Al desactivar esta opción, perderá la protección adicional que aporta la garantía multirriesgos.',
    EXPLANATION: 'Por defecto, las opciones están seleccionadas, pero las puede deseleccionar haciendo clic.',
    EXPLANATION2: 'And cancellation is possible at any time.',
    EXPLANATION3: 'No se efectuará ningún pago.',
    HINT: 'Puede activar o desactivar una opción en todo momento',
    MAIN_HEADLINE: 'Elija sus opciones',
    SUBMIT_BUTTON: 'Confirmar'
  },
  subscriptionPayment: {
    ERRORS: {
      UNKNOWN: 'Se ha producido un problema durante el pago, inténtelo de nuevo'
    },
    GIFT_CARD: {
      EXPLANATION: 'Introduzca el código que se encuentra en el reverso de la tarjeta que se le entregó en su paquete de seguimiento.',
      I_HAVE_ONE: 'Tengo una tarjeta de regalo',
      NEED_HELP: '¿Necesita ayuda con las tarjetas regalo?',
      TITLE: 'Utilice su regalo',
      USE: 'Utilizar la tarjeta de regalo'
    },
    HEADLINE: 'Resumen previo al pago',
    PROMOCODE_TEXT: 'Tengo un código de cupón:',
    PROMO_CODE: 'Código del cupón',
    PROMO_CODE_GIFT_CARD: 'Tarjeta de regalo',
    SATISFIED_OR_REFUNDED: 'Garantía satisfecho o reembolsado',
    SATISFIED_OR_REFUNDED_DESCRIPTION: '30 días para probarla sin riesgo; si no está satisfecho, le reembolsamos.',
    SELECT_PAYMENT_MEAN: 'Seleccione una forma de pago antes de pagar y activar su suscripción.',
    SUBMIT_BUTTON: 'Pagar la suscripción',
    SUBMIT_BUTTON2: 'Activar el localizador',
    SUMMARY: 'Resumen de su elección'
  },
  subscriptionProcess: {
    GIFT_CARD_EXPLANATION: 'Introduzca su método de pago preferido para activar su localizador de forma gratuita.',
    GIFT_CARD_EXPLANATION2: 'Esta información es necesaria por motivos legales relacionados con el uso de tarjetas SIM.',
    RETURN_TO_APP: 'Volver a la aplicación',
    RETURN_TO_MAP: 'Volver al mapa',
    RETURN_TO_TRACKERS: 'Volver al mapa',
    STEPS: [
      'Localizador',
      'Suscripción',
      'Opciones',
      'Resumen'
    ]
  },
  subscriptionSelection: {
    BEST_OFFER: 'MEJOR OFERTA',
    CANCELABLE_AFTER_THREE_MONTHS: 'Posibilidad de cancelación en cualquier momento después de los tres primeros meses',
    CANCEL_ANYTIME: 'Posibilidad de cancelación en cualquier momento',
    COMMITMENT_TEXT: 'Puede rescatarse en cualquier momento tras el periodo mínimo de compromiso de 3 meses.',
    FEATURES: '',
    FEATURES_HEADLINE: 'Todas las suscripciones incluyen:',
    HEADLINE: 'Elja su suscripción',
    MIN_THREE_MONTHS: 'Compromiso mínimo de tres meses',
    MONEY_BACK: 'Todas las suscripciones se facturan de inmediato, pero pueden cancelarse en un plazo de 30 días, con reembolso integral del importe abonado.',
    PAYMENT_MEANS: 'Pago por domiciliación bancaria o tarjeta de crédito',
    REFUNDED_INFO: 'Le reembolsaremos la suscripción sin condiciones.',
    REFUNDED_TEXT: 'Satisfecho o reembolsado 30 días.',
    SIM_CARD_WARNING: 'El localizador tiene una tarjeta SIM ya integrada. Esto explica que sea necesario contratar una suscripción (para cubrir los gastos de servicio).',
    SUBMIT_BUTTON: 'Confirmar',
    XMAS_OFFER: {
      BODY: 'Sin embargo estamos obligados a pedirle elegir su futura suscripción ahora (por razones de seguridad, como el aparato viene con una tarjeta sim). No habrá ningún débito antes del final de los 6 meses gratis, y puede cancelar esta suscripción en cualquier momento hasta un mes antes de la fecha del comienzo efectivo.',
      TITLE: 'Su localizador está precargado con 6 meses de suscripción regalado por su ser querido.'
    },
    features: [
      'Plazo de devolución de 30 días',
      'Localización GPS ilimitada',
      'Notificaciones push (aplicación) y correos electrónicos',
      '10 minutos de llamada de voz al mes'
    ]
  },
  subscriptions: {
    BASE: 'Suscripción',
    CANCEL_MODAL: {
      HEADLINE: '¿Desea cancelar la renovación automática de su suscripción?',
      HINT: 'Su suscripción seguirá activa hasta el {{date}}, pero no se renovará automáticamente. Después de esta fecha, ya no podrá localizar su localizador.'
    },
    CHANGE_PLAN: {
      ADD_OPTIONS: 'Añadir una opción',
      EXPLANATION: 'El cambio de suscripción se efectuará al finalizar el período en curso.',
      HEADLINE: '¿Cambiar de suscripción?'
    },
    DETAILS: {
      ACTIVATE_BUTTON: {
        LABEL: 'Activar suscripción'
      },
      ACTIVE_CASE: {
        MANAGE_BUTTON: 'Gestionar suscripción',
        RENEWAL_INFO: 'Tu abono se renovará automáticamente el {{date}} por un total de {{price}}.'
      },
      CANCELED: {
        EXPIRED_MAIN: 'Su suscripción ha caducado. Reactive la suscripción para poder localizar su GPS.',
        EXPIRED_SEC: 'Caducado',
        UNCANCEL_BUTTON_LABEL: 'Reactivar la renovación automática de la suscripción',
        WILL_EXPIRE_DAYS: 'Caduca dentro de {{count}} día',
        WILL_EXPIRE_DAYS_plural: 'Caduca dentro de {{count}} días',
        WILL_EXPIRE_MAIN: 'Se ha cancelado la renovación automática de su suscripción. Caducará el {{date}}.'
      },
      CANCEL_RESILLIATION: 'Cancelar la solicitud de anulación',
      NEW_CASE: {
        INFO_TEXT: 'Ha habido un problema con su pago. Inténtelo de nuevo o cambie el método de pago.',
        PAY_BUTTON: 'Pagar y activar',
        WAITING_TEXT: 'Actualmente estamos procesando su pago por la suscripción de este localizador.'
      },
      NO_SUB: {
        INFO_TEXT: 'Active la suscripción para empezar a usar su localizador.'
      },
      RESILLIATION_ASKED: 'Baja solicitada',
      SUBSCRIBE_BUTTON: {
        LABEL: 'Contratar una suscripción'
      },
      SUPPORT_TEXT: '¿Tiene problemas para activar su localizador? Contacte con el servicio de <1>asistencia</1>.',
      TRIAL_PERIOD_CASE: {
        END_INFO: 'Su periodo de prueba finalizará el {{ date }}'
      }
    },
    OFFERS: {
      SAVING_PER_YEAR: 'ahorre {{amount}}/año'
    },
    OFFERS_TEXT: {
      MOST_POPULAR: 'La más popular',
      ONE_MONTH: {
        INFO: 'Sin compromiso, cancelable en cualquier momento',
        TITLE: 'Paquete de 1 mes'
      },
      ONE_YEAR: {
        INFO: '15 días de prueba adicionales: 30 días en total',
        TITLE: 'Paquete de 1 año'
      },
      THREE_YEAR: {
        INFO: '1 año de garantía adicional del fabricante: 3 años en total',
        INFO2: '3-year manufacturer\'s warranty (instead of two).',
        TITLE: 'Paquete de 3 años'
      }
    },
    OVERVIEW: {
      OPTIONS: 'Detalles de las opciones',
      REG_DETAILS: 'Detalles del localizador',
      REPLACE_TRACKER_BUTTON: {
        LABEL: 'Sustituir el localizador'
      },
      SUB_DETAILS: 'Datos de la suscripción'
    },
    PLAN_NAMES: {
      FALLBACK: 'Suscripción',
      ONE_MONTH: 'Abono mensual',
      ONE_YEAR: 'Suscripción prepaga 1 año',
      THREE_YEARS: 'Suscripción prepaga 3 años',
      TRIAL_PERIOD: 'Periodo de prueba',
      TWO_YEARS: 'Suscripción 2 años'
    },
    STATUS: {
      ACTIVE: 'Suscrito',
      CANCELED: 'Cancelado',
      INACTIVE: 'No suscrito',
      NEW: 'Activo',
      PENDING: 'Pendiente'
    },
    TRACKER_DETAILS: {
      REGISTRATION_DATE: 'Fecha de activación',
      WARRANTY: 'Garantía estándar'
    }
  },
  superLive: {
    ACTIVE_TEXT: 'El modo Super live está activo',
    HEADLINE: 'Modo Super live',
    MESSAGES: {
      SUCCESS_ACTIVATED: 'Modo Super live activado correctamente: su localizador enviará una posición cada {{frequency}} segundos durante 5 minutos'
    },
    TIME: 'Una posición cada {{frequency}} segundos'
  },
  support: {
    FORM: {
      MESSAGE: {
        LABEL: 'Su mensaje*',
        PLACEHOLDER: ''
      },
      PROBLEM: {
        AVAILABLE_OPTIONS: 'Opciones disponibles',
        LABEL: 'Tipo de problema experimentado*'
      },
      SUBJECT: {
        LABEL: 'Asunto de su solicitud*',
        PLACEHOLDER: ''
      },
      TRACKER: {
        LABEL: 'Localizador asociado a su solicitud',
        PLACEHOLDER: 'Seleccione el localizador'
      }
    },
    HEADLINE: 'Asistencia',
    HELP_CENTER: 'Centro de ayuda',
    HELP_TEXT: '¿Necesita ayuda? Visite nuestro centro de ayuda para hallar respuesta a su pregunta. También puede ponerse en contacto con nosotros utilizando el siguiente formulario; nuestro equipo le responderá en un plazo de 24 horas hábiles.',
    MAIN_TEXT: 'Nuestro centro de ayuda contiene todos los artículos que pueden responder a sus preguntas.',
    MESSAGES: {
      SUBMIT_ERROR: 'Ha ocurrido un error, inténtelo de nuevo',
      SUBMIT_SUCCESS: 'Mensaje enviado correctamente'
    },
    PROBLEM_TYPES: {
      CHARGE: 'Mi localizador no se carga normalmente',
      DOWNLOAD_APP: 'No consigo descargar la aplicación',
      GPS_INVALID: 'Mi señal de GPS indica que no es válida',
      GPS_PRECISION: 'La posición GPS de mi localizador es incorrecta',
      LEDS_FIXED: 'Los dos ledes de mi localizador permanecen fijos',
      NO_GEOFENCE_ALERTS: 'Recibo alertas falsas de entrada o salida de la zona',
      NO_NOTIFICATIONS: 'No recibo las notificaciones',
      OTHER: 'Otros',
      SIGN_IN: 'No puedo conectarme a mi espacio personal',
      SUBSCRIPTION: 'Tengo un problema con mi suscripción',
      TRACKERS_PARAMS: 'No puedo configurar mi localizador',
      TRACKER_NOT_CONNECTED: 'Mi localizador indica que no está conectado'
    },
    SUBMITTED_TICKET: 'Hemos recibido su solicitud, gracias.\nLe hemos enviado un correo electrónico de confirmación (revise su bandeja de correo no deseado).',
    SUB_TEXT: 'Si estas explicaciones no son suficientes, también puede ponerse en contacto con un miembro de nuestro equipo de servicio, que le responderá en un plazo de 24 horas laborables.',
    TITLE: '¿Necesitas ayuda?',
    URGENT_NUMBER: 'Si su solicitud es urgente, puede ponerse en contacto con nuestro Servicio de Atención al Cliente al llamar el \n+33 187 660 083 de 10 a 12 h y de 14 a 17 h, de lunes a viernes.'
  },
  trackerLeftPanel: {
    CALL: 'Llamada',
    CTR_ONLY_SECONDS: 'Le quedan {{SECONDS}} segundo(s) de llamada telefónica para el mes en curso.',
    CTR_WITH_MINUTES: 'Le quedan {{MINUTES}} minutos(s) y {{SECONDS}} segundo(s) de llamada telefónica para el mes en curso.',
    FLASH: 'Linterna',
    GOOD_GPS_ACCURACY: 'Precisión GPS media',
    LAST_POSITION: 'Última posición conocida:',
    MEDIUM_GPS_ACCURACY: 'Precisión GPS media',
    OFFLINE: 'Localizador desconectado',
    POOR_GPS_ACCURACY: 'Precisión GPS mala',
    RING: 'Sonido',
    SMS_AVAILABLE: 'SMS disponibles : {{SMS_count}}',
    VIBRATE: 'Vibración'
  },
  trackerSettings: {
    BUTTONS: {
      LABELS: {
        BUTTON: 'Botón'
      },
      PLACEHOLDER_BUTTON: 'Introduzca el mensaje enviado por el botón {{button_number}}',
      TEXT: 'Configurar el mensaje asignado a cada botón del localizador. Lo recibirá en las notificaciones activadas al pulsar el botón correspondiente.'
    },
    CONTACTS: {
      ADD_EMAIL_BUTTON: 'Agregar dirección de correo electrónico',
      ADD_PHONE_BUTTON: 'Agregar teléfono móvil',
      EMAILS_LABEL: 'Direcciones de correo electrónico para las notificaciones',
      MAIL: {
        PLACEHOLDER: 'votre@email.com'
      },
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Ha ocurrido un error, inténtelo de nuevo',
        UPDATE_SUCCESS: 'Contactos actualizados correctamente'
      },
      NUMBERS_LABEL: 'Números de teléfono para las notificaciones por SMS',
      PHONE: {
        PLACEHOLDER: '+33600000000'
      }
    },
    DISPLAY: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema',
        UPDATE_SUCCESS: 'Configuración actualizada correctamente'
      },
      MODAL: {
        HEADLINE: 'Seleccionar icono',
        TEXT: 'Puede seleccionar el icono que desee en la lista a continuación. Representará a su localizador en el mapa.'
      },
      TRACKER_ICON: {
        EDIT_TEXT: 'Cambiar icono',
        LABEL: 'Icono del localizador'
      },
      TRACKER_NAME: {
        LABEL: 'Nombre del localizador',
        PLACEHOLDER: 'Nombre que se mostrará en el mapa'
      },
      TRACKER_TYPE: {
        CHANGE: 'Cambiar de tipo de localizador',
        MODIFIED_SUCCESS: 'Tipo de localizador modificado con éxito'
      }
    },
    GPS_FREQUENCY: {
      BATTERY_USAGE: 'Duración media de la batería:',
      BATTERY_USAGE_LEVELS: {
        HIGH: 'Alta',
        LOW: 'Baja',
        MEDIUM: 'Media'
      },
      GEOFENCE_NOTIFICATIONS: 'Espera media de la sensibilidad de las alertas de zona:',
      HINT: 'Una frecuencia más rápida reduce la duración de la batería, pero mejora la sensibilidad de las alertas de entrada y salida de zona. Por el contrario, una frecuencia más lenta aumenta la duración de la batería, pero reduce la sensibilidad de las alertas de entrada y salida de zona.',
      TEXT: 'Seleccione el intervalo de tiempo del informe del GPS'
    },
    NOTIFICATION_SETTINGS: {
      APP: 'Aplicación',
      BATTERY: {
        HINT: 'Envía una notificación a todos los contactos si el localizador llega al nivel de batería baja.',
        TITLE: 'Batería'
      },
      BUTTONS: {
        HINT: 'Envía una notificación a todos los contactos cuando se pulsa uno de los botones laterales. El mensaje configurado para este botón se incluirá en la notificación.',
        TITLE: 'Botones laterales'
      },
      GEOFENCES: {
        HINT: 'Envía una notificación a todos los contactos si el localizador entra o sale de una zona configurada.',
        TITLE: 'Zonas seguras'
      },
      MAIL: 'Correo',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Ha ocurrido un error, inténtelo de nuevo',
        UPDATE_SUCCESS: 'La configuración de las notificaciones se ha actualizado correctamente'
      },
      SMS: 'SMS',
      SOS: {
        HINT: 'Envía una notificación a todos los contactos cuando se pulsa el botón SOS.',
        TITLE: 'Llamada SOS'
      },
      WARNING: {
        SMS: {
          LINK: 'Haga clic aquí para activarla.',
          TEXT: 'Atención: si desea recibir las alertas por SMS, debe activar la opción correspondiente.'
        }
      }
    },
    SECTION_TITLES: {
      BUTTONS: 'Botones',
      CONTACTS: 'Contactos para las notificaciones',
      DISPLAY: 'General',
      GPS_FREQUENCY: 'Intervalo de tiempo del informe del GPS',
      NOTIFICATION_SETTINGS: 'Tipos de notificaciones para cada alerta',
      SOS: 'Número SOS',
      TRAINING: 'Función teléfono'
    },
    TRAINING_SOS: {
      DIRECT_CALL: 'Llamar directamente a este número en caso de emergencia.',
      INPUT_PLACEHOLDER: '+33600000000',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Ha ocurrido un error, inténtelo de nuevo',
        UPDATE_SUCCESS: 'Número de teléfono actualizado correctamente'
      },
      SOS_TEXT: 'Número de teléfono que recibirá las llamadas en caso de emitirse una alerta SOS',
      TRAINING_TEXT: 'Escriba el número de teléfono móvil al que llamará por defecto el localizador si se utiliza la función de teléfono desde la aplicación. Este número puede editarse en cada solicitud de llamada desde la aplicación.'
    }
  },
  trackers: {
    ADD_TRACKER_BUTTON: {
      LABEL: 'Añadir un nuevo localizador'
    },
    EXPIRATION: {
      EXPIRED: 'Ha caducado la suscripción de {{tracker}}',
      WILL_EXPIRE: 'La suscripción de {{tracker}} caducará dentro de {{days}} días'
    },
    LINKS: {
      ACTIVITY: 'Seguimiento de la actividad',
      GEOFENCES: 'Zonas',
      HISTORY: 'Historial',
      SETTINGS: 'Configuración',
      SUBSCRIPTION: 'Suscripción'
    }
  },
  trackersPositionRefresh: {
    MESSAGES: {
      NOT_REFRESHED: 'Su localizador no ha podido obtener nuevas posiciones GPS',
      REFRESHING: 'Espere, se está solicitando una actualización.',
      SUCCESS: 'Posiciones GPS de los localizadores actualizadas correctamente.'
    }
  },
  transferPage: {
    ADD_TRACKER: 'Quiero añadir un nuevo localizador a mi cuenta',
    BACK_TO_TRACKERS: 'Volver al mapa',
    FIRST_TITLE: '¿Qué quieres hacer exactamente?',
    FROM_HEADLINE: 'Localizador que se sustituirá:',
    HEADLINE: 'Sustituir un localizador',
    IMEI_LABEL: 'Número IMEI',
    INFO: 'La suscripción actual y los ajustes se transferirán al nuevo localizador después de la sustitución. El historial de posiciones GPS del localizador anterior se perderá.',
    MESSAGES: {
      ERROR_UNKNOWN: 'Ha ocurrido un error, inténtelo de nuevo',
      SUCCESS: 'Nuevo localizador asignado correctamente',
      WRONG_TYPE: 'Der GPS-Sender mit dieser IMEI-Nummer entspricht nicht dem Typ Ihres alten Senders.'
    },
    NAME_LABEL: 'Nombre del localizador',
    PURCHASE: '¿Aún no tiene un localizador Weenect? <1>Puede adquirirlo aquí</1>',
    REPLACE_BUTTON: {
      LABEL: 'Sustituir'
    },
    REPLACE_TRACKER: 'Quiero sustituir mi localizador por uno nuevo',
    TO_HEADLINE: 'Nuevo localizador:'
  },
  webappDisconnection: {
    BUG: 'No conectado',
    MANUAL: 'Has apagado el localizador. Pulsa el botón ON para encenderlo.',
    NO_BATTERY: 'Tu localizador se apagó porque no le quedaba batería.',
    NO_SUB: 'No tienes suscripción para este localizador. Escoge una desde la página'
  },
  wifiZones: {
    AIRPLANE_TEXT: 'Advertencia: en el modo avión, mientras el localizador esté en la zona de economía de energía, ya no podrás hacer sonar, vibrar o actualizar manualmente su posición.',
    DEEP_SLEEP_TITLE: 'Modo avión',
    DISPLAY_BUTTON: 'Mostrar la zona en el mapa',
    DISPLAY_TITLE: 'Notificaciones',
    IN_AIRPLANE: '- en modo avión',
    IN_OUT_TEXT: 'Entradas y salidas de zona:',
    LAST_POS_IN_WIFI: 'En la zona de economía de energía desde {{date}}',
    MAIL_NOTIF: 'Notificaciones mail',
    NOTIF_TEXT: 'Recibe una notificación por cada entrada o salida de la zona.',
    POPIN: {
      AIRPLANE: {
        BUTTON_VALIDATE: 'Salir del modo avión',
        CONTENT: 'Ya no puedes comunicarte con tu localizador mientras esté en la z<span><span>ona de&nbsp;</span></span>economía de energía. Si quieres recuperar la funcionalidad completa,<b> desactiva el modo avión y saca el localizador&nbsp;de la zona. </b>',
        TITLE: 'Tu localizador está en modo avión en la zona de economía de energía {{wifiName}}.'
      },
      BUTTON_CLOSE: 'Para más tarde',
      BUTTON_VALIDATE: 'Desactivar esta zona',
      CONTENT: 'En esta zona, <strong>el envío automático de las posiciones GPS de su localizador está desactivado</strong> porque sabemos que se encuentra en la zona. <strong>Puede desactivar esta zona en cualquier momento desde la pestaña "Localizador"</strong>.',
      TITLE: 'Tu localizador está en la zona de economía de energía {{wifiName}}.'
    },
    PUSH_NOTIF: 'Notificaciones push',
    SMS_NOTIF: 'Notificaciones SMS',
    TITLE: 'Zona de economía de energía',
    ZONE_ACTIVATION: 'Activar la zona'
  }
}
